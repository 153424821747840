
import React from 'react'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../../redux/slices/utilSlice';


function SidebarResponseClose() {

  const dispatch = useDispatch();

  const handleSidebarResponsiveClose = () => {
    dispatch(toggleSidebar({type : "SIDEBAR"}));
  }


  return (
    <div className="container">
            <div className="hamburger-menu" id="sidebarResponsiveClose" onClick={handleSidebarResponsiveClose}>
                <img src="/icones/menu.svg" alt="" />
            </div>
    </div>
  )
}

export default SidebarResponseClose