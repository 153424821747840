import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, modalSelector, openModal } from '../../redux/slices/modalSlice';
import "./Modal.css";
import Modal from "react-modal";
import { loginSelector } from '../../redux/slices/authSlice';
import { adminUpdateSelectedUserThunk, adminUpdateUserThunk, updateUserThunk, userSelector } from '../../redux/slices/userSlice';
import { deSelectAllParticipants, participantSelector } from '../../redux/slices/participantSlice';
function ModalUser() {


    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState('');
    const {selectedParticipants} = useSelector(participantSelector);
    const categoryRef = useRef(null);
    const state = useSelector(loginSelector);
    const {selectedUser, loading} = useSelector(userSelector);
    const { isOpen, modalType } = useSelector(modalSelector);
    const customStyles = {
        content: {
          width: "40%", // Ajustez la largeur du modal selon vos besoins
          margin: "auto", // Centre le contenu horizontalement
          borderRadius: "10px", //
          height: "45%",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Couleur de fond du backdrop (noir avec opacité)
          // boxShadow:'0px 6.400000095367432px 15px 0px rgba(0, 40, 210, 0.4), 0px 1.2000000476837158px 4px 0px rgba(0, 40, 210, 0.1)',
        },
      };

      useEffect(()=> {
        setSelectedCategory(selectedUser.category);
      
      }, [selectedUser])


      const handleCategoryChange = (event) => {
        event.preventDefault();
        
        setSelectedCategory(event.target.value)
      }

      const handleSubmit = (event) => {
        event.preventDefault();

        console.log(selectedParticipants);

        if(selectedParticipants.length > 0){

          const data = {
            category: selectedCategory,
            id : selectedParticipants,
            token : state.adminToken
        }
        dispatch(adminUpdateSelectedUserThunk(data)).then((data) => {
            if (data.status === 201 || data.status === 200) {
              dispatch(
                openModal({
                  type: "SNACK-BAR",
                  data: { severity: "success", message: data.data.message },
                })
              );

              dispatch(deSelectAllParticipants());
            } else {
              dispatch(
                openModal({
                  type: "SNACK-BAR",
                  data: { severity: "error", message: data.data.message },
                })
              );

              dispatch(deSelectAllParticipants());
            }
          });
         
        }else{

          const data = {
            category: selectedCategory,
            id : selectedUser.id,
            token : state.adminToken
        }
        dispatch(adminUpdateUserThunk(data)).then((d) => {
            if (d.status === 201 || d.status === 200) {
              dispatch(
                openModal({
                  type: "SNACK-BAR",
                  data: { severity: "success", message: d.data.message },
                })
              );
            } else {
              dispatch(
                openModal({
                  type: "SNACK-BAR",
                  data: { severity: "error", message: d.data.message },
                })
              );
            }
          });
        }
       
      }


  return (
    <Modal
      isOpen={isOpen && modalType === "USER"}
      onRequestClose={() => dispatch(closeModal("USER"))}
      contentLabel="termes et conditions d utilisation"
      portalClassName="modal-term"
      style={customStyles}
    >
      <div className="mt-5">
        <div className="modal-custom-header">
          <div className="modal-header-text">
            <h2> {selectedParticipants.length > 0 ? ("Modifier les utilisateurs") : ("Modifier l'utilisateur " + (selectedUser && selectedUser.name )) }</h2>
          </div>
        </div>

        <div className="modal-custom-body mt-3">
            <div className="row">
                <div className="col-md-2 d-flex align-items-center">
                    <label htmlFor="">Categorie</label>
                </div>

                <div className="col-md-10 mt-2">
                    <select name="" id=""  className="form-select"  style={{fontSize:'1.5rem'}}  
                    value={selectedCategory}
                      ref={categoryRef}
                      onChange={handleCategoryChange}>
                        <option value="PARTICIPANT">
                          PARTICIPANT
                        </option>

                        <option value="VIP">
                          VIP
                        </option>

                        <option value="SPEAKER">
                          SPEAKER
                        </option>

                        <option value="PRESSE">
                          PRESSE
                        </option>

                        <option value="ORGANISATION">
                          ORGANISATION
                        </option>

                        <option value="GUEST">
                          GUEST
                        </option>

                        <option value="OFFICIEL">
                          OFFICIEL
                        </option>

                        <option value="PARTENAIRE">
                          PARTENAIRE
                        </option>

                        <option value="SPONSOR">
                          SPONSOR
                        </option>

                        <option value="MEDIA">
                          MEDIA
                        </option>
                    </select>
                </div>


                <div className="d-flex justify-content-center mt-5" >
                    <div className="btn-open-mail align-self-center" onClick={handleSubmit}>
                        <span>{loading ? 'Loading...' : 'Valider'}</span>
                    </div>
                </div>
            </div>
          
        
        </div>

        <div className="modal-footer-c"></div>
      </div>
    </Modal>
  )
}

export default ModalUser