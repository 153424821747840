import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, modalSelector } from "../../redux/slices/modalSlice";
import "./Modal.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function ModalComponent() {
  const { isOpen, modalType, data } = useSelector(modalSelector);

  const dispatch = useDispatch();
  const go = useNavigate();
  const { t } = useTranslation();

  const customStyles = {
    content: {
      width: "80%", // Ajustez la largeur du modal selon vos besoins
      margin: "auto", // Centre le contenu horizontalement
      borderRadius: "10px", //
      height: "75%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Couleur de fond du backdrop (noir avec opacité)
      // boxShadow:'0px 6.400000095367432px 15px 0px rgba(0, 40, 210, 0.4), 0px 1.2000000476837158px 4px 0px rgba(0, 40, 210, 0.1)',
    },
  };

  const goToPaymentPage = () => {
    dispatch(closeModal("SUCCESS-REGISTER"));
    window.open("mailto:example@gmail.com");
    // go('/payment');
  }

  return (
    <Modal
      isOpen={isOpen && modalType === "SUCCESS-REGISTER"}
      onRequestClose={() => dispatch(closeModal("SUCCESS-REGISTER"))}
      contentLabel="termes et conditions d utilisation"
      portalClassName="modal-term"
      style={customStyles}
    >
      <div className="modal-container">
        <div className="modal-custom-header">
          <div className="modal-header-text">
            <h3>{t(('modal-title'))}</h3>
          </div>
        </div>

        <div className="modal-custom-body">
          <p >
            {t(('modal-body-p-1'))}
          </p>

          <p>
          {t(('modal-body-p-2'))}
          </p>

          <p>
          {t(('modal-body-p-3'))}
            <br />
            <span className="cp">contact@cyberafricaforum.com</span>{" "}
          </p>

          <p> {t(('sincerly'))}</p>

          <p> {t(('organizing_team'))}</p>

          <div className="d-flex justify-content-center" >
              <div className="btn-open-mail align-self-center" onClick={goToPaymentPage}>
                <span>{t(('email_box'))}</span>
              </div>
          </div>

          {/* <p>Si vous n'avez pas reçu de mail veuillez cliquer sur ce bouton</p>

          <div className="d-flex justify-content-center" >
              <div className="btn-open-mail align-self-center" onClick={goToPaymentPage}>
                <span>Renvoyer</span>
              </div>
          </div> */}
        </div>

        <div className="modal-footer-c"></div>
      </div>
    </Modal>
  );
}

export default ModalComponent;
