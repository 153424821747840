import React from 'react'
import Sidebar from '../../components/admin/Sidebar'
import AdminNav from '../../components/admin/AdminNav';
import SidebarResponseClose from '../../components/admin/SidebarResponseClose';

function AdminConfigEmail() {
  return (
    <div class="backC">
        
    
        <Sidebar/>
    <main class="main-content main-nelly">
       <AdminNav title="Emailing"/>
      
        <div>
        <SidebarResponseClose/>

      

            <div class="tab--top ">
               <div class="container">
                    <div class="d-flex align-items-center justify-content-between mb-5">
                        <h3>Configuration email</h3>
                    </div>

                    <div class="tableDash">
                        <div class="tableDashtop">
                            <div class="d-flex">
                                <div class="input--group1 mb-0">
                                    <i class="fas fa-search"></i>
                                    <input type="text" placeholder="Rechercher"/>
                                </div>

                                <div class="input--group mb-0">
                                    <select name="" id="">
                                        <option value="">Trier par : <span> Aujourd'hui</span></option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <button class="btn--site"><i class="fas fa-plus"></i> Ajouter</button>
                            </div>
                        </div>

                        <div class="ov--table" >
                            <table class="table" >
                                <thead>
                                  <tr>
                                    <th scope="col">
                                        <input type="checkbox" name="" id=""/>
                                    </th>
                                    <th scope="col">Type d'eamil</th>
                                    <th scope="col">Contenu</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                       <td>
                                        <input type="checkbox" name="" id="" />
                                       </td>
                                       <td>Invitation</td>
                                       <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, ratione ab fugit aliquam voluptate accusamus</td>
                                        <td>
                                            <button type="button" class="btn btn-secondary btn-sm"><i class="fas fa-ellipsis-h"></i></button>
                                            <div class="menu--tab">
                                                <ul>
                                                    <li>
                                                        <a href=""><i class="fas fa-share-square"></i> Modifier</a>
                                                    </li>

                                                    <li>
                                                        <a href=""><i class="far fa-trash-alt"></i> Supprimer</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
               </div>
            </div>
        </div>
    </main>

   </div>
  )
}

export default AdminConfigEmail