import React, { useEffect, useState } from "react";
import "./MainPayment.css";
import { openModal } from "../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { checkoutThunk, paymentSelector } from "../redux/slices/paymentSlice";
import { useAuth } from "../context/AuthContext";
import { loginSelector } from "../redux/slices/authSlice";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { utilSelector } from "../redux/slices/utilSlice";
import { useTranslation } from "react-i18next";
import i18next, { changeLanguage } from "i18next";
import {
  countrySelector,
  fetchCountriesThunk,
} from "../redux/slices/countrySlice";

function MainPayment() {
  const [payments, setPayments] = useState([
    { id: 1, title: "Mobile Money", selected: true, lang: "fr" },
    { id: 2, title: "Carte Bancaire", selected: false, lang: "fr" },
    { id: 1, title: "Mobile Money", selected: true, lang: "en" },
    { id: 2, title: "Bank Card", selected: false, lang: "en" },
  ]);
  const [userData, setUserData] = useState(null);
  const { loading: countryLoading, data: countries } =
    useSelector(countrySelector);
  const [amount, setAmount] = useState(100000);
  const [name, setName] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState(null);
  const [stateError, setStateError] = useState(null);
  const [adress, setAdress] = useState("");
  const [countryId, setCountryId] = useState("");
  const [nameError, setNameError] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [adressError, setAdressError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [cityError, setCityError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [countryIdError, setCountryIdError] = useState("");
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [participationType, setParticipationType] =
    useState("Pass Change Maker");
  const [error, setError] = useState("");
  const { token } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const [validateError, setValidateError] = useState("");
  const { loading } = useSelector(paymentSelector);

  const handleCheckboxChange = () => {
    setValidateError(""); // Effacez l'erreur lorsque la case à cocher est modifiée
    setIsChecked(!isChecked);
  };

  const { t } = useTranslation();
  const decryptToken = () => {
    // Created using Crypt::encryptString('Hello world.') on Laravel.
    // If Crypt::encrypt is used the value is PHP serialized so you'll
    // need to "unserialize" it in JS at the end.
    var encrypted = token;

    // The APP_KEY in .env file. Note that it is base64 encoded binary
    var key = "E7OMgdYepGdnfgWq/2IwUUAHlS7sT04sKk3uiTA9vas=";

    // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
    // So let's base64 decode the string to get them.
    encrypted = atob(encrypted);
    encrypted = JSON.parse(encrypted);

    // console.log("Laravel encryption result", encrypted);

    // IV is base64 encoded in Laravel, expected as word array in cryptojs
    const iv = CryptoJS.enc.Base64.parse(encrypted.iv);

    // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
    const value = encrypted.value;

    // Key is base64 encoded in Laravel, word array expected in cryptojs
    key = CryptoJS.enc.Base64.parse(key);

    // Decrypt the value, providing the IV.
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      iv: iv,
    });

    // CryptoJS returns a word array which can be
    // converted to string like this
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);

    return decrypted;
  };

  const handleChangeLang = (lan) => {
    localStorage.setItem("locales", lan);
    changeLanguage(lan);
  };

  useEffect(() => {}, [i18next.language]);

  useEffect(() => {
    dispatch(fetchCountriesThunk());

    return () => {};
  }, []);

  useEffect(() => {
    try {
      const decryptedData = decryptToken(token);
      console.log(decryptedData);
      const startIndex = decryptedData.indexOf("{");
      const trimmedJsonString = decryptedData
        .substring(startIndex)
        .trim()
        .replace(/"$/, "");
      const jsonString = trimmedJsonString.slice(0, -2);
      const decryptedDataParsed = JSON.parse(jsonString);
      console.log(decryptedDataParsed);
      setUserData(decryptedDataParsed);
      setName(decryptedDataParsed.name);
      setLastName(decryptedDataParsed.last_name);
      setEmail(decryptedDataParsed.email);
      setPhone(decryptedDataParsed.phone_number);
      setHasDiscount(decryptedDataParsed.has_discount);
      setDiscountPercentage(decryptedDataParsed.discount_percentage);

      console.log(decryptedDataParsed.has_discount);
      console.log(decryptedDataParsed.discount_percentage);
      console.log(decryptedDataParsed);
      if (decryptedDataParsed.language === "English") {
        handleChangeLang("en");
      } else {
        handleChangeLang("fr");
      }
    } catch (e) {
      console.log(e);
      setError("Désolé, Vous n'avez pas accès à cette page");
    }
  }, [token]);

  const dispatch = useDispatch();
  const selectPayment = (id) => {
    const updatedPayments = payments
      .map((payment) => {
        if (payment.id === id) {
          return { ...payment, selected: true };
        } else {
          return { ...payment, selected: false };
        }
      });
    setPayments(updatedPayments);
  };

  const handleCategory = () => {
    console.log("Category");
    dispatch(
      openModal({
        type: "CATEGORY",
      })
    );
  };

  const { catData: categoryData, catDataEn: categoryDataEn } =
    useSelector(utilSelector);

  useEffect(() => {
    if (i18next.language === "en") {
      setAmount(categoryDataEn.amount);
      setParticipationType(categoryDataEn.title);
      console.log(categoryDataEn.amount);
      console.log("en");
    } else {
      console.log(categoryData.amount);
      setAmount(categoryData.amount);
      setParticipationType(categoryData.title);
      console.log("fr");
    }
  }, [categoryData, categoryDataEn, i18next.language]);

  const handleValidate = () => {
    // "customer_id": "172", id de l'utilisateur depuis le serveur
    // les states à gérer
    // "amount": 100,
    // "customer_name": "KOUADIO",
    // "customer_surname": "Francisse",
    // "customer_email": "harrissylver@gmail.com",
    // "customer_phone_number": "+225004315545",
    // "customer_address": "Antananarivo",
    // "customer_city": "Antananarivo",
    // "customer_country": "CM",
    // "customer_state": "CM",
    // "customer_zip_code": "065100",

    let formulaireValide = true;

    if (!isChecked) {
      setValidateError("Vous devez cocher la case pour valider.");
      return;
    }


    if(payments.find(p => p.id === 2).selected){
      if (name === "") {
        if(i18next.language==='en'){
          setNameError("Name field is required");
        }else{
          setNameError("Le champs nom est requis");
        }
        
        formulaireValide = false;
      } else {
        setNameError("");
      }


      if (lastName === "") {
        if(i18next.language==='en'){
          setLastNameError("lastName field is required");
        }else{
          setLastNameError("Le champ prénom est requis");
        }
        
        formulaireValide = false;
      } else {
        setLastNameError("");
      }


      if (countryId === "") {
        if(i18next.language==='en'){
          setCountryIdError("Country field is required");
        }else{  
          setCountryIdError("Le champ pays est requis");
        }
        formulaireValide = false;
      } else {
        setCountryIdError("");
      }

      if (city === "") {
        if(i18next.language==='en'){
          setCityError("city field is required");
        }else{  
          setCityError("Le champ ville est requis");
        }
        formulaireValide = false;
      } else {
        setCityError("");
      }


      if (adress === "") {
        if(i18next.language==='en'){
          setAdressError("Adress field is required");
        }else{  
          setAdressError("Le champ Adresse est requis");
        }
        formulaireValide = false;
      } else {
        setAdressError("");
      }


      if (zipCode === "") {
        if(i18next.language==='en'){
          setZipCodeError("zipCode field is required");
        }else{  
          setZipCodeError("Le champ Code Postal est requis");
        }
        formulaireValide = false;
      } else {
        setZipCodeError("");
      }
    }


    
    if (formulaireValide) {
    let a = 50000;
    if (i18next.language === "en") {
      if (hasDiscount === 1) {
        if (amount === 83) {
          a = 50000 - (50000 * discountPercentage) / 100;
        } else {
          a = 150000 - (150000 * discountPercentage) / 100;
        }
      } else {
        if (amount === 83) {
          a = 50000;
        } else {
          a = 150000;
        }
      }
    }

    if (i18next.language === "fr") {
      if (hasDiscount === 1) {
        if (amount === 77) {
          a = 50000 - (50000 * discountPercentage) / 100;
        } else {
          a = 150000 - (150000 * discountPercentage) / 100;
        }
      } else {
        if (amount === 77) {
          a = 50000;
        } else {
          a = 150000;
        }
      }
    }




    let countryCode = "CI";

    if(countryId){
      const country = countries.find((c) => c.id == countryId);
      console.log(country);
      const countryCode = country.country_code || "CI";
      console.log(countryCode);
    }
  
    console.log(countryCode);
    console.log(name);
    console.log(lastName);
    console.log(adress);
    console.log(city);
    console.log(state);
    console.log(zipCode);


    const notify_url =
      process.env.REACT_APP_API_URL + "/api/payments/notify_url";
    const return_url = "https://www.cyberafricaforum.com";
    var data = {
      apikey: process.env.REACT_APP_CINETPAY_API_KEY,
      site_id: process.env.REACT_APP_CINETPAY_SITE_ID,
      transaction_id: Math.floor(Math.random() * 100000000).toString(), //
      amount: a,
      currency: "XOF",
      alternative_currency: "",
      description: "CYBER AFRICA FORUM PASS",
      customer_id: userData.id,
      customer_name: name,
      customer_surname: lastName,
      customer_email: email,
      customer_phone_number: phone,
      customer_address: adress,
      customer_city: city,
      customer_country: countryCode,
      customer_state: state,
      customer_zip_code: zipCode,
      notify_url: notify_url,
      return_url: return_url,
      channels: "ALL",
      metadata: "user1",
      lang: "FR",
      with_discount: hasDiscount,
      discount_percentage: discountPercentage,
      invoice_data: {
        Donnee1: "",
        Donnee2: "",
        Donnee3: "",
      },
    };
    // console.log(data);
    dispatch(checkoutThunk(data)).then((d) => {
      // console.log(d.data.data);

      window.open(d.data.data.payment_url);
      // dispatch(openModal({type: "SUCCESS-PAYMENT"}))
    });

    }else{

    }
  };
  return (
    <>
      {error ? (
        <div
          className="d-full d-flex justify-content-center align-items-center"
          style={{ height: "40vh" }}
        >
          <h2 className="red">{error}</h2>
        </div>
      ) : (
        <>
          {userData ? (
            <main className="container mb-5 mt-5 main-payment-karim">
              <div className="row">
                <div className="col-md-5">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="label">
                        {t("payment_personal_info")}
                      </span>
                    </div>
                    <div className="cp modify">
                      <i className="fa-solid fa-pen me-1"></i>
                      <span>{t("payment_modify")}</span>
                    </div>
                  </div>

                  <div className="mt-2">
                    <div>
                      <label htmlFor="">
                        {" "}
                        {t("payment_name_and_last_name")}{" "}
                      </label>{" "}
                      <br />
                      <span className="label">
                        {name + " " + lastName ?? "N/A"}
                      </span>
                      <br />
                    </div>
                  </div>

                  <div className="mt-2">
                    <div>
                      <label htmlFor=""> {t("email")} </label> <br />
                      <span className="label">{email}</span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <span className="label">{t("choose_pass")}</span>
                    </div>
                    <div className="cp modify" onClick={handleCategory}>
                      <i className="fa-solid fa-pen me-1"></i>
                      <span>{t("payment_modify")}</span>
                    </div>
                  </div>

                  <div
                    className="card-custom d-flex align-items-center mt-4 mb-2"
                    onClick={handleCategory}
                  >
                    <div className="icone d-flex align-items-center justify-content-center">
                      <div className="diamond">
                        <img src="/icones/diamond.svg" alt="" />
                      </div>
                    </div>
                    <div className="montant">
                      <h4>{participationType}</h4>
                      <h5>
                        {hasDiscount ? amount - (amount * 15) / 100 : amount}{" "}
                        <span style={{ fontSize: "1.5rem" }}>
                          {t("currency")}
                        </span>
                      </h5>
                    </div>
                  </div>

                  {payments.find((p) => p.id === 2).selected ? (
                    <div>
                      <div className="mb-5 mt-5">
                        <span className="label"> {t("card_info")}</span>
                      </div>

                      <div className="row align-items-center">
                        <div>
                          <span className="label"> {t("name")}</span>
                        </div>
                        <div className="d-flex col-md-10 form-gr">
                          <div className="input-gr-text">
                            <input
                              type="text"
                              name=""
                              id=""
                              placeholder={t("name_placeholder")}
                              className="input"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                            />
                            {nameError && <p className="error">{nameError}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="row  align-items-center">
                        <div>
                          <span className="label">{t("last_name")}</span>
                        </div>
                        <div className="d-flex col-md-10 last_name form-gr">
                          <div className="input-gr-text">
                            <input
                              type="text"
                              name=""
                              id=""
                              placeholder={t("last_name_placeholder")}
                              className="input"
                              onChange={(e) => setLastName(e.target.value)}
                              value={lastName}
                            />
                            {lastNameError && (
                              <p className="error">{lastNameError}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div>
                        <span className="label"> {t('country')}</span>
                      </div>

                      <div className="row align-items-center">
                        <div className="d-flex col-md-10 form-gr">
                          <div className="input-gr-text">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) => setCountryId(e.target.value)}
                              style={{ fontSize: "1.5rem" }}
                            >
                              <option value="-1" selected>
                                {t("choose")}
                              </option>
                              {countries &&
                                countries.map((d) => {
                                  return (
                                    d && (
                                      <option value={d.id} key={d.id}>
                                        {d.name}
                                      </option>
                                    )
                                  );
                                })}
                            </select>

                            {countryIdError && (
                              <p className="error">{countryIdError}</p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2 mb-2">
                        <span className="label">{t('city')}</span>
                      </div>

                      <div className="d-flex col-md-10 form-gr mb-2">
                        <div className="input-gr-text">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder={t('city')}
                            className="input"
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                          />
                          {cityError && <p className="error">{cityError}</p>}
                        </div>
                      </div>

                      <div className="mt-2 mb-2">
                        <span className="label">{t('address')}</span>
                      </div>

                      <div className="d-flex col-md-10 form-gr mb-2">
                        <div className="input-gr-text">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder={t('address')}
                            className="input"
                            onChange={(e) => setAdress(e.target.value)}
                            value={adress}
                          />
                          {adressError && (
                            <p className="error">{adressError}</p>
                          )}
                        </div>
                      </div>

                      <div className="mt-2 mb-2">
                        <span className="label">
                          {t("state")}
                        </span>
                      </div>

                      <div className="d-flex col-md-10 form-gr mb-2">
                        <div className="input-gr-text">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder={t('state_placeholder')}
                            className="input"
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                          />
                          {stateError && <p className="error">{stateError}</p>}
                        </div>
                      </div>

                      <div className="mt-2 mb-2">
                        <span className="label">{t("zip_code")}</span>
                      </div>

                      <div className="d-flex col-md-10 form-gr mb-2">
                        <div className="input-gr-text">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder={t("zip_code")}
                            className="input"
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                          />
                          {zipCodeError && (
                            <p className="error">{zipCodeError}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-5">
                  <div className="d-flex ">
                    <div>
                      <span className="label">{t("payment_mode")}</span>
                    </div>
                  </div>

                  <div className="mt-1">
                    <span className="cp fs-8">{t("payment_mode_desc")}</span>
                  </div>

                  {console.log(payments)}
                  {payments
                    .filter((p) => p.lang === i18next.language)
                    .map((pay) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between mt-4"
                          onClick={() => selectPayment(pay.id)}
                          key={pay.id}
                        >
                          <div
                            className={
                              pay.selected
                                ? "card-mobile-money d-flex  flex-column checked"
                                : "card-mobile-money d-flex  flex-column "
                            }
                          >
                            <div>
                              <h6 className="money-title">{pay.title}</h6>
                            </div>
                            <div>
                              {pay.id === 1 ? (
                                <div className="d-flex justify-content-space-between">
                                  <div
                                    className="img-container"
                                    style={{ width: "25%" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_URL}/images/mtn.png`}
                                      alt=""
                                    />
                                  </div>

                                  <div
                                    className="img-container"
                                    style={{ width: "25%" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_URL}/images/orange.png`}
                                      alt=""
                                    />
                                  </div>

                                  <div
                                    className="img-container"
                                    style={{ width: "25%" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_URL}/images/moov.png`}
                                      alt=""
                                    />
                                  </div>

                                  <div
                                    className="img-container"
                                    style={{ width: "25%" }}
                                  >
                                    <img
                                      src={`${process.env.REACT_APP_URL}/images/wave.png`}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  <div className="d-flex justify-content-space-between">
                                    <div style={{ width: "33%" }}>
                                      <img
                                        src={`${process.env.REACT_APP_URL}/images/master.png`}
                                        alt=""
                                      />
                                    </div>
                                    <div style={{ width: "33%" }}>
                                      <img
                                        src={`${process.env.REACT_APP_URL}/images/visa.png`}
                                        alt=""
                                      />
                                    </div>

                                    <div style={{ width: "33%" }}>
                                      <img
                                        src={`${process.env.REACT_APP_URL}/images/paypal.png`}
                                        alt=""
                                      />
                                    </div>
                                  </div>{" "}
                                </>
                              )}
                            </div>
                          </div>
                          <div className="select-check d-flex justify-content-center align-items-center">
                            <div
                              className={
                                pay.selected
                                  ? "select d-flex align-items-center justify-content-center check-active"
                                  : "select d-flex align-items-center justify-content-center"
                              }
                            >
                              {" "}
                              {/*check-active */}
                              {pay.selected ? (
                                <i className="fa-solid fa-check"></i>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="mt-4 mb-4">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    {t("payment_accept")}{" "}
                    <a href="https://www.cyberafricaforum.com/mention-legale">
                      {t("payment_privacy_policy")}
                    </a>
                  </label>

                  {validateError && (
                    <div style={{ color: "red" }}>{validateError}</div>
                  )}
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <div
                  className="finalize-payment mt-5 mb-3"
                  onClick={handleValidate}
                >
                  <span> {loading ? "Loading..." : t("submit_payment")}</span>
                </div>
              </div>
            </main>
          ) : (
            "user not found"
          )}
        </>
      )}
    </>
  );
}

export default MainPayment;
