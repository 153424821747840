import React from 'react'
import './Header.css';
import Nav from '../nav/Nav';
import { useTranslation } from 'react-i18next';


function Header(props) {

  const { t } = useTranslation();
  return (
    <div className="page-banner-area pt-100 pb-75">
        <Nav/>
        <div className="container">
            <div className="row justify-content-center mt-5">
                <div className="col-lg-6">
                    <span className="font1 tc2">{t('registration')} </span>
                    <h1 className="font2 fw-bold text-white mt-3" style={{fontSize:'3.5rem'}}>{props.title}</h1>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header