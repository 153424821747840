import React, { useEffect, useState } from "react";
import AdminNav from "../../components/admin/AdminNav";
import Sidebar from "../../components/admin/Sidebar";
import SidebarResponseClose from "../../components/admin/SidebarResponseClose";
import ParticipantRow from "../../components/admin/ParticipantRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersThunk,
  searchUserThunk,
  userSelector,
} from "../../redux/slices/userSlice";
import { loginSelector } from "../../redux/slices/authSlice";
import ParticipantLoader from "./../../components/loader/ParticipantLoader";
import ActionMenuTab from "./../../components/admin/ActionMenuTab";

function AdminPartners() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { users, loading, totalPages } = useSelector(userSelector);
  const state = useSelector(loginSelector);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);
  const [visiblePages, setVisiblePages] = useState([]);
  const [date, setDate] = useState("date");

  console.log(users);
  //   const generateVisiblePages = () => {
  //     const interval = 3; // Nombre de pages avant et après la page actuelle à afficher
  //     let start = Math.max(1, page - interval);
  //     let end = Math.min(totalPages, page + interval);
  //     let pages = [];
  //     for (let i = start; i <= end; i++) {
  //         pages.push(i);
  //     }
  //     setVisiblePages(pages);
  // };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const changePage = (pageNumber) => {
    setPage(pageNumber);
  };

  const renderPageButtons = (totalPages) => {
    let buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => changePage(i)}
          disabled={i === page}
          className="btn me-2"
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  function useDebounceValue(value, time = 100) {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounceValue(value);
      }, time);
      return () => {
        clearTimeout(timer);
      };
    }, [value, time]);

    return debounceValue;
  }

  useEffect(() => {
    dispatch(
      searchUserThunk({
        query: debounceValue,
        token: state.adminToken,
        page: page,
        date: date,
      })
    );
  }, [debounceValue, page, date]);

  return (
    <div className="backC">
      <Sidebar />
      <main className="main-content main-nelly">
      <AdminNav title="Partners"/>
        <div>
          <SidebarResponseClose />

          <div class="tab--top ">
            <div class="container">
              <div class="d-flex align-items-center justify-content-between mb-5">
                <h3>partenaires</h3>

                <ul
                  class="nav nav-pills nav-pills2 mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Tout
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Founder <span class="badge rounded-pill ">23</span>
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Gold <span class="badge rounded-pill ">23</span>
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-disabled-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-disabled"
                      type="button"
                      role="tab"
                      aria-controls="pills-disabled"
                      aria-selected="false"
                    >
                      Silver<span class="badge rounded-pill ">23</span>
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-diamond-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-diamond"
                      type="button"
                      role="tab"
                      aria-controls="pills-diamond"
                      aria-selected="false"
                    >
                      Diamond<span class="badge rounded-pill ">23</span>
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-platinum-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-platinum"
                      type="button"
                      role="tab"
                      aria-controls="pills-platinum"
                      aria-selected="false"
                    >
                      Platinum<span class="badge rounded-pill ">23</span>
                    </button>
                  </li>
                </ul>
              </div>

              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabindex="0"
                >
                  <div class="tableDash">
                    <div class="tableDashtop">
                      <div class="d-flex">
                        <div class="input--group1 mb-0">
                          <i class="fas fa-search"></i>
                          <input type="text" placeholder="Rechercher" />
                        </div>

                        <div class="input--group mb-0">
                          <select name="" id="">
                            <option value="">
                              Trier par : <span> Aujourd'hui</span>
                            </option>
                          </select>
                        </div>
                      </div>

                      <div>
                        <button class="btn--tr">
                          <i class="fas fa-bell"></i> Envoyer une notification
                        </button>
                        <button class="btn--tr">
                          <i class="fas fa-envelope-open-text"></i> Envoyer un
                          document
                        </button>
                        <button class="btn--tr">
                          <i class="fas fa-upload"></i> Importer
                        </button>
                        <button class="btn--tr">
                          <i class="fas fa-download"></i> Exporter
                        </button>
                      </div>

                      <div>
                        <button class="btn--site">
                          <i class="fas fa-plus"></i> Ajouter
                        </button>
                      </div>
                    </div>

                    <div class="ov--table">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" />
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Badge</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="val">Validé</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="att">En attente</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="rej">Rejeté</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="val">Validé</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="att">En attente</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="rej">Rejeté</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td>002203</td>
                            <td>23/01/2024 11:05</td>
                            <td>Standard</td>
                            <td>Akissi fofana</td>
                            <td>Infographe</td>
                            <td>akissifof@mail.com</td>
                            <td>0707000000</td>
                            <td class="rej">Rejeté</td>
                            <td>Oui</td>
                            <td>
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                              >
                                <i class="fas fa-ellipsis-h"></i>
                              </button>
                              <div class="menu--tab">
                                <ul>
                                  <li>
                                    <a href="">
                                      <i class="far fa-check-circle"></i>{" "}
                                      Valider
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-times"></i> Rejeter
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-eye"></i> Voir les
                                      détails
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-envelope-open-text"></i>
                                      Envoyer un document
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-bell"></i> Envoyer une
                                      notification
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-share-square"></i>{" "}
                                      Modifier
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="far fa-trash-alt"></i> Supprimer
                                    </a>
                                  </li>

                                  <li>
                                    <a href="">
                                      <i class="fas fa-ban"></i> Bannir
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-disabled"
                  role="tabpanel"
                  aria-labelledby="pills-disabled-tab"
                  tabindex="0"
                >
                  ...
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AdminPartners;
