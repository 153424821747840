import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, modalSelector } from "../../redux/slices/modalSlice";
import "./Modal.css";
import { useNavigate } from "react-router-dom";
function ModalPayment() {
  const { isOpen, modalType } = useSelector(modalSelector);

  // console.log(modalType, isOpen);

  const dispatch = useDispatch();
  const go = useNavigate();

  const customStyles = {
    content: {
      width: "70%", // Ajustez la largeur du modal selon vos besoins
      margin: "auto", // Centre le contenu horizontalement
      borderRadius: "10px", //
      height: "70%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Couleur de fond du backdrop (noir avec opacité)
      // boxShadow:'0px 6.400000095367432px 15px 0px rgba(0, 40, 210, 0.4), 0px 1.2000000476837158px 4px 0px rgba(0, 40, 210, 0.1)',
    },
  };

  const goToPaymentPage = () => {
    dispatch(closeModal("SUCCESS-PAYMENT"));
    go('/payment');
  }

  return (
    <Modal
      isOpen={isOpen && modalType === "SUCCESS-PAYMENT"}
      onRequestClose={() => dispatch(closeModal("SUCCESS-PAYMENT"))}
      contentLabel="termes et conditions d utilisation"
      portalClassName="modal-term"
      style={customStyles}
    >
      <div className="modal-container mt-5">
        <div className="modal-custom-header">
          <div className="modal-header-text">
            <h3>Payement effectué avec succès !</h3>
          </div>
        </div>

        <div className="modal-custom-body mt-3">
          <p>
            Nouv vous remercions pour votre paiment. Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
            Mollitia, architecto!
            Lorem ipsum dolor sit amet.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore placeat iure delectus minima reiciendis 
            officiis soluta iste a! A numquam totam quibusdam fuga deleniti consequuntur, possimus inventore 
            repudiandae vitae soluta.
          </p>
         
          <div className="d-flex justify-content-center mt-5" >
              <div className="btn-open-mail align-self-center" onClick={goToPaymentPage}>
                <span>Accéder à mon profil</span>
              </div>
          </div>
        </div>

        <div className="modal-footer-c"></div>
      </div>
    </Modal>
  );
}

export default ModalPayment;
