import { createSlice } from '@reduxjs/toolkit';
import axios from './../../../node_modules/axios/lib/axios';

const initialState = {
	loading: false,
	hasErrors: false,
	errMsg: '',
	data : []
};

export const countrySlice = createSlice({
	name: 'country',
	initialState,
	reducers: {
		country: (state, { payload }) => {
			state.loading = true;
		},

		countrySuccess: (state, { payload }) => {
			state.loading = false;
			state.data = payload;
		},

		countryFailure: (state, { payload }) => {
			state.loading = false;
			state.hasErrors = true;
			state.errMsg = payload;
			console.log('failure');
		},
	},
});
export const { country, countrySuccess, countryFailure } =
	countrySlice.actions;
export const countrySelector = (state) => state.country;

export const fetchCountriesThunk = () => {
	return async (dispatch) => {
		dispatch(country());
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/countries`);
			const countries =  response.data.data;
			// Identifier l'élément "cybersécurité"
			const cybersecurite = countries.find(pays => pays.name === "Côte d'Ivoire");

			// Filtrer "cybersécurité" du tableau initial
			const autresPays = countries.filter(pays => pays.name !== "Côte d'Ivoire");

			// Trier les autres pays par ordre alphabétique
			autresPays.sort((a, b) => a.name.localeCompare(b.name));

			// Concaténer "cybersécurité" avec le reste des pays triés
			const tab = [cybersecurite, ...autresPays];
			// console.log(tab)
			dispatch(countrySuccess(tab));
			return response;
		} catch (err) {
			console.error(err.message);
			dispatch(countryFailure(err.message));
			return err.response;
		}
	};
};
export default countrySlice.reducer;
