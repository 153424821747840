import React, { createContext, useContext, useReducer, useState } from 'react';
import authSlice, { loginSelector } from '../redux/slices/authSlice';
import { useSelector } from 'react-redux';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const {user, isAdmin, isAuthenticated, token} = useSelector(loginSelector);
  const [state, setState] = useState({
    user, isAdmin, isAuthenticated, token
  });

  // console.log(state)

  

  
  return (
    <AuthContext.Provider value={{ state }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
