import React from 'react'
import SidebarResponseClose from '../../components/admin/SidebarResponseClose'
import AdminNav from '../../components/admin/AdminNav'
import Sidebar from '../../components/admin/Sidebar'
import { useSelector } from 'react-redux';
import { paymentSelector } from '../../redux/slices/paymentSlice';
import { addThousandSeparators, formatDate } from '../../features/helpers';
import ParticipantLoader from '../../components/loader/ParticipantLoader';
import PaymentLoader from '../../components/loader/PaymentLoader';

function AdminPaiement() {
    const {data, loading} = useSelector(paymentSelector);
    function getStatusClassName(status) {
        switch(status) {
          case 'SUCCESS':
            return 'approved';
          case 'FAILED':
            return 'revoked';
          default:
            return 'revoked';
        }
      }

      function getDiscountClassName(status) {
        switch(status) {
          case 1:
            return 'approved';
          case 0:
            return 'revoked';
          default:
            return 'revoked';
        }
      }

      
  return (
    <div className="backC">
    <Sidebar />
    <main className="main-content main-nelly">
    <AdminNav title="Paiements"/>
      <div>
        <SidebarResponseClose />

        <div className="tab--top ">
               <div className="container">

                <div className="tab--pay">
                    <div className="row">
                        <div className="col-md-12 col-lg-5">
                            <div className="tab--pay__top">
                                <div className="part--icon part--iconbd">
                                <i class="fa-solid fa-credit-card"></i>
                                </div>

                                <h3>Montant total de paiement enregistré</h3>

                                <h1 style={{fontWeight: 'bold'}}> <span style={{fontSize: '3rem'}}>{data.paymentTotalAmount && addThousandSeparators(data.paymentTotalAmount) }</span> XOF</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-7">
                            <div className="tab--pay__bottom">
                                <h3>Pourcentage de paiement par moyens de paiement</h3>

                                <div className="d-flex justify-content-between">
                                    <div className="percentBar percentBarbs">
                                        <p>Mobile money</p>
                                        <h1>{data.paymentTotalMomoPercent && (data.paymentTotalMomoPercent * 100).toFixed(2)}%</h1>
                                    </div>

                                    <div className="percentBar percentBarbl">
                                        <p>Carte Bancaire</p>
                                        <h1>{data.paymentTotalMomoPercent && (100 - (data.paymentTotalMomoPercent *100)).toFixed(2)}%</h1>
                                    </div>

                                    {/* <div className="percentBar percentBargr">
                                        <p>Mobile money</p>
                                        <h1>60%</h1>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-5">
                    <h3>Gestion des paiements</h3>
                </div>

                <div className="tableDash">
                    <div className="tableDashtop">
                        <div className="d-flex">
                            <div className="input--group1 mb-0">
                                <i className="fas fa-search"></i>
                                <input type="text" placeholder="Rechercher" />
                            </div>

                            <div className="input--group mb-0">
                                <select name="" id="">
                                    <option value="">Trier par : <span> Aujourd'hui</span></option>
                                </select>
                            </div>
                        </div>

                        <div>
                            {/* <button className="btn--site"><i className="fas fa-plus"></i> Ajouter</button> */}
                        </div>
                    </div>

                    <div className="ov--table" >
                        <table className="table" >
                            <thead>
                                <tr>
                                <th scope="col">
                                    <input type="checkbox" name="" id="" />
                                </th>
                                <th scope="col">Id transaction</th>
                                <th scope="col">Date</th>
                                <th scope="col">Numero</th>
                                <th scope="col">Montant</th>
                                <th scope="col">Currency</th>
                                <th scope="col">Type</th>
                                <th scope="col">Status</th>
                                <th scope="col">User</th>
                                <th scope="col">Avec remise</th>
                                <th scope="col">Pourcentage</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(!!data.paymentTotal && !loading )? (
                            data.paymentTotal.map((payment) => {
                              return (
                                ( <tr>
                                    <td>
                                    <input type="checkbox" name="" id="" />
                                    </td>
                                    <td>{payment.transaction_id}</td>
                                    <td>{formatDate(payment.created_at)}</td>
                                    <td>{payment.customer_phone_number}</td>
                                    <td>{addThousandSeparators(payment.amount)} XOF</td>
                                    <td>{payment.currency}</td>
                                    <td>{payment.type}</td>
                                    <td className={getStatusClassName(payment.status)}>{payment.status}</td>
                                    <td>{payment.customer_name + " " + payment.customer_surname}</td>
                                    <td className={getDiscountClassName(payment.with_discount)}>{payment.with_discount ? "oui" : "non"}</td>
                                    <td>{payment.discount_percentage ? payment.discount_percentage : 0 } %</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary btn-sm"><i className="fas fa-eye"></i></button>
                                        <div className="menu--tab">
                                            <ul>
                                                <li>
                                                    <a href=""><i className="fas fa-share-square"></i> Modifier</a>
                                                </li>

                                                <li>
                                                    <a href=""><i className="far fa-trash-alt"></i> Supprimer</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>)
                              );
                            })
                          ) : (
                            <PaymentLoader />
                          )}
                              
                            </tbody>
                        </table>
                    </div>
                </div>
                


           

                {/* <div className="d-flex align-items-center justify-content-between mb-5">
                    <h3>Gestion des codes promos</h3>
                </div>

                <div className="tableDash">
                    <div className="tableDashtop">
                        <div className="d-flex">
                            <div className="input--group1 mb-0">
                                <i className="fas fa-search"></i>
                                <input type="text" placeholder="Rechercher" />
                            </div>

                            <div className="input--group mb-0">
                                <select name="" id="">
                                    <option value="">Trier par : <span> Aujourd'hui</span></option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <button className="btn--site"><i className="fas fa-plus"></i> Ajouter</button>
                        </div>
                    </div>

                    <div className="ov--table" >
                        <table className="table" >
                            <thead>
                                <tr>
                                <th scope="col">
                                    <input type="checkbox" name="" id="" />
                                </th>
                                <th scope="col">Code promo</th>
                                <th scope="col">Cible</th>
                                <th scope="col">Valeur</th>
                                <th scope="col">Nombre d'utilisations</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                    <input type="checkbox" name="" id="" />
                                    </td>
                                    <td>CPM000JZ</td>
                                    <td>Vip participant</td>
                                    <td>20%</td>
                                    <td>4893</td>
                                    <td>
                                        <button type="button" className="btn btn-secondary btn-sm"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="menu--tab">
                                            <ul>
                                                <li>
                                                    <a href=""><i className="fas fa-share-square"></i> Modifier</a>
                                                </li>

                                                <li>
                                                    <a href=""><i className="far fa-trash-alt"></i> Supprimer</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
               </div>
            </div>

        </div>
      </main>
    </div>
  )
}

export default AdminPaiement