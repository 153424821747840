import { createSlice } from '@reduxjs/toolkit';
import axios from './../../../node_modules/axios/lib/axios';

const initialState = {
	loading: false,
	hasErrors: false,
	errMsg: '',
	data : []
};

export const activityAreaSlice = createSlice({
	name: 'activityArea',
	initialState,
	reducers: {
		activityArea: (state, { payload }) => {
			state.loading = true;
		},

		activityAreaSuccess: (state, { payload }) => {
			state.loading = false;
			state.data = payload;
		},

		activityAreaFailure: (state, { payload }) => {
			state.loading = false;
			state.hasErrors = true;
			state.errMsg = payload;
			console.log('failure');
		},
	},
});
export const { activityArea, activityAreaSuccess, activityAreaFailure } =
	activityAreaSlice.actions;
export const activityAreaSelector = (state) => state.activityArea;

export const fetchActivityAreasThunk = () => {
	return async (dispatch) => {
		dispatch(activityArea());
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/activity-areas`);
			const activities = response.data.data;
			const activity = activities.find(pays => pays.name === "Cybersécurité");
			const activity2 = activities.find(pays => pays.name === "Autre");

			// Filtrer "cybersécurité" du tableau initial
			const OtherActivities = activities.filter(pays => (pays.name !== "Cybersécurité" && pays.name !== "Autre"));

			// Trier les autres pays par ordre alphabétique
			OtherActivities.sort((a, b) => a.name.localeCompare(b.name));

			// Concaténer "cybersécurité" avec le reste des pays triés
			const tab = [activity, ...OtherActivities, activity2];
			dispatch(activityAreaSuccess(tab));
			return response;
		} catch (err) {
			console.error(err.message);
			dispatch(activityAreaFailure(err.message));
			return err.response;
		}
	};
};
export default activityAreaSlice.reducer;
