import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeActionMenuTab, utilSelector } from "../../redux/slices/utilSlice";
import { adminDownloadBadgeThunk, adminDownloadSelectedBadgeThunk, approveSelectedUsersThunk, approveSelectedUsersWithDiscountThunk, approveUserThunk, approveUserWithDiscountThunk, declineSelectedUsersThunk, declineUserThunk, deleteUserThunk, downloadBadgeThunk, getTotalUsersThunk, selectUser, sendBadgeMailSelectedUsersThunk, sendBadgeMailThunk, sendDsiMailSelectedUsersThunk, sendDsiMailThunk, sendInvitedMailSelectedUsersThunk, sendInvitedMailThunk, sendPaymentReminderMailSelectedUsersThunk, sendPaymentReminderMailThunk, updateUserThunk, userSelector } from "../../redux/slices/userSlice";
import { loginSelector } from "../../redux/slices/authSlice";
import { openModal } from "../../redux/slices/modalSlice";
import { deSelectAllParticipants } from "../../redux/slices/participantSlice";

function ActionMenuTab() {
  const { data, isOpen, modalType } = useSelector(utilSelector);
  const {selectedUser} = useSelector(userSelector);
  const dispatch = useDispatch();
  const { loading } = useSelector(userSelector);
  const state = useSelector(loginSelector);
  const menuRef = useRef(null);

  // Fonction pour gérer les clics en dehors du menu
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
    }
  };

  // Effet pour ajouter l'écouteur d'événements lors du montage et le supprimer lors du démontage
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUpdateUser = (id, type) => {

    if(typeof id === "object") {
      console.log(id);
      console.log('plusieurs elements selectionnés');

      const data = {
        id: id,
        status: type,
        token: state.adminToken,
      };

      if(type === "APPROVED-WITH-DISCOUNT"){
        dispatch(approveSelectedUsersWithDiscountThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
           
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }

      

      if(type === "INVITED"){
        dispatch(sendInvitedMailSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
           
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }

      if(type === "DSI"){
        dispatch(sendDsiMailSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
           
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }

      if(type === "BADGE"){
        dispatch(sendBadgeMailSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
           
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }


      if(type === "PAYMENT-REMINDER"){
        dispatch(sendPaymentReminderMailSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
           
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }
      
  
      if(type === "REVOKED"){
        dispatch(declineSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }
  
      if(type === "APPROVED"){
        dispatch(approveSelectedUsersThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
            dispatch(getTotalUsersThunk({token : state.adminToken}));
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
          } else {
            
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.error },
              })
            );
            dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          }
        });
      }

      dispatch(deSelectAllParticipants());
      return false;
    }
    const data = {
      id: id,
      status: type,
      token: state.adminToken,
    };


    if(type === "DSI"){
      dispatch(sendDsiMailThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });
    }

    if(type === "INVITED"){
      dispatch(sendInvitedMailThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });
    }

    if(type === "BADGE"){
      dispatch(sendBadgeMailThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });
    }


    if(type === "PAYMENT-REMINDER"){
      dispatch(sendPaymentReminderMailThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });
    }

    if(type === "REVOKED"){
      dispatch(declineUserThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });
    }

    if(type === "APPROVED"){
      dispatch(approveUserThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });

      dispatch(deSelectAllParticipants());
    }

    if(type === "APPROVED-WITH-DISCOUNT"){
      dispatch(approveUserWithDiscountThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
          dispatch(getTotalUsersThunk({token : state.adminToken}));
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB"}));
        } else {
          
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
        }
      });

      dispatch(deSelectAllParticipants());
    }
   
  };

  const handleDeleteUser = (id) => {

    const data = {
        id: id,
        token: state.adminToken,
      };


   

      dispatch(deleteUserThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "success", message: data.data.message },
            })
          );
        } else {
          dispatch(closeActionMenuTab({ type: "ACTION-MENU-TAB" }));
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
        }
      });
  }

  const handleModifyUser = (id) => {
    if(typeof id !== "object") {
      dispatch(openModal({ type : "USER"}));
      dispatch(selectUser(id));
    }else {

      // dispatch(selectUser(id));
      dispatch(openModal({ type : "USER"}));

      // dispatch(
      //   openModal({
      //     type: "SNACK-BAR",
      //     data: { severity: "warning", message: "Veuillez svp modifier les participants un à un" },
      //   })
      // );
    }
    
  }

  const handleGenerateBadge = (d) => {

    console.log(d);
    const da = {
      id: d.id,
      token: state.adminToken
    }

    if(typeof d.id === "object") {
      console.log(d.id);
     
      dispatch(adminDownloadSelectedBadgeThunk(da)).then((data) => {
        if (data.status === 201 || data.status === 200) {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Badges.zip");
            document.body.appendChild(link);
            link.click();
            // Libérez l'URL de l'objet blob après le téléchargement.
            window.URL.revokeObjectURL(url);
        } else {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
        }
      });

      dispatch(deSelectAllParticipants());

      return false;
    }


    const data = {
      id: d.id,
      token: state.adminToken
    }
    dispatch(adminDownloadBadgeThunk(data)).then((data) => {
      if (data.status === 201 || data.status === 200) {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Badge_" + d.name + "_" + d.last_name + ".pdf");
        document.body.appendChild(link);
        link.click();
        // Libérez l'URL de l'objet blob après le téléchargement.
        window.URL.revokeObjectURL(url);
      } else {
        dispatch(
          openModal({
            type: "SNACK-BAR",
            data: { severity: "error", message: data.data.error },
          })
        );
      }
    });
  }

  return (
    <div
      className={
        !(isOpen && modalType === "ACTION-MENU-TAB")
          ? "menu--tab"
          : "menu--tab ActionMenuTab--active"
      }
      ref={menuRef}
    >
      <ul>
        <li
          onClick={(e) => {
            e.preventDefault();
            handleUpdateUser(data.id, "APPROVED");
          }}
        >
          <a href="#e">
            <i className="far fa-check-circle"></i> Valider
          </a>
        </li>

        <li onClick={() => handleUpdateUser(data.id, "REVOKED")}>
          <a href="#e">
            <i className="fas fa-times"></i> Rejeter
          </a>
        </li>

        <li
          onClick={(e) => {
            e.preventDefault();
            handleUpdateUser(data.id, "APPROVED-WITH-DISCOUNT");
          }}
        >
          <a href="#e">
            <i class="fa-solid fa-tag"></i> Valider Avec Remise
          </a>
        </li>

        
        <li onClick={() => handleUpdateUser(data.id, "DSI")}>
          <a href="#e">
            <i class="fa-regular fa-envelope"></i> Guest
          </a>
        </li>


        <li onClick={() => handleUpdateUser(data.id, "INVITED")}>
          <a href="#e">
            <i class="fa-regular fa-envelope"></i> Spéciaux
          </a>
        </li>

        <li onClick={() => handleUpdateUser(data.id, "BADGE")}>
          <a href="#e">
            <i class="fa-regular fa-envelope"></i> Send Badge
          </a>
        </li>

        

        <li onClick={() => handleUpdateUser(data.id, "PAYMENT-REMINDER")}>
          <a href="#e">
            <i class="fa-solid fa-repeat"></i> Relance paiement
          </a>
        </li>

        {/* <li>
          <a href="">
            <i className="far fa-eye"></i> Voir les détails
          </a>
        </li>

        <li>
          <a href="">
            <i className="fas fa-envelope-open-text"></i>Envoyer un document
          </a>
        </li>

        <li>
          <a href="">
            <i className="fas fa-bell"></i> Envoyer une notification
          </a>
        </li> */}

        <li>
          <a href="#" onClick={() => handleModifyUser(data.id)}>
            <i className="fas fa-share-square"></i> Modifier
          </a>
        </li>

        <li  onClick={(e) =>{e.preventDefault();  handleGenerateBadge({id : data.id, name : data.name, last_name : data.last_name })}}>
          <a href="#">
            <i className="fas fa-envelope-open-text"></i>Générer le badge
          </a>
        </li>


        <li onClick={() => handleDeleteUser(data.id)}>
          <a href="#e">
            <i className="far fa-trash-alt"></i> Supprimer
          </a>
        </li>


        {loading ? (
        <div className="d-flex justify-content-end">
             <li>
            <a href="#e">
                <i className="fa-solid fa-spinner"></i> Loading ...
            </a>
          </li>
        </div>
         
        ) : null}
      </ul>
    </div>
  );
}

export default ActionMenuTab;
