import React from 'react';
import QRCode from 'react-qr-code';
import './Badge.css';
import html2pdf from 'html2pdf.js';

const BadgePdf = ({ name, jobTitle, photoURL, id }) => {
    const componentRef = React.useRef();

    const handlePrint = () => {
        const opt = {
            margin: 1,
            filename: 'badge.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        const element = componentRef.current;

        html2pdf().from(element).set(opt).save();
    };

    return (
        <>
            <div className="custom-badge" ref={componentRef}>
                <div className="badge-header">
                    <img className="badge-photo" src="images/logo-c.png" alt="Employee" />
                </div>
                <div className="badge-bande">
                    <img src="images/bande.jpg" alt="" />
                </div>
                <div className="badge-details">
                    <h1>PARTICIPANT</h1>
                    <div className="badge-qrcode">
                        <QRCode value={`ID: sbcjkqxjnijs`} />
                    </div>
                    <div className='badge-identity'>
                        <h4>Karim Kouyate</h4>
                        <h4>Lead Developer chez Novate</h4>
                        <h4>Novate</h4>
                    </div>
                </div>
            </div>
            {/* <div>
                <button onClick={handlePrint}>Télécharger PDF</button>
            </div> */}
        </>
    );
};

export default BadgePdf;
