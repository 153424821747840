import React, { useEffect, useState } from "react";
import AdminNav from "../../components/admin/AdminNav";
import Sidebar from "../../components/admin/Sidebar";
import SidebarResponseClose from "../../components/admin/SidebarResponseClose";
import ParticipantRow from "../../components/admin/ParticipantRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersThunk,
  searchConfirmedUserThunk,
  searchRejectedUserThunk,
  searchUserThunk,
  searchValidatedUserThunk,
  searchWaitingUserThunk,
  userSelector,
} from "../../redux/slices/userSlice";
import { loginSelector } from "../../redux/slices/authSlice";
import ParticipantLoader from "./../../components/loader/ParticipantLoader";
import ActionMenuTab from "./../../components/admin/ActionMenuTab";
import { openModal } from "../../redux/slices/modalSlice";
import { openActionMenuTab } from "../../redux/slices/utilSlice";
import { deSelectAllParticipants, participantSelector, selectAllParticipants } from "../../redux/slices/participantSlice";
import { Parser } from '@json2csv/plainjs';
import XLSX, { utils, writeFile } from 'xlsx';
function AdminParticipant() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [waitingPage, setWaitingPage] = useState(1);
  const [validatedPage, setValidatedPage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [confirmedPage, setConfirmedPage] = useState(1);
  
  
  const {totalConfirmedUsersPages, totalConfirmedUsersCount, waitingUsers, rejectedUsers,confirmedUsers,  validatedUsers, users, loading,totalRejectedUsersPages,totalWaitingUsersPages, totalValidatedUsersPages, totalUsersPages, totalWaitingUsersCount, totalValidatedUsersCount, totalRevokedUsersCount } = useSelector(userSelector);
  
  const state = useSelector(loginSelector);
  const [query, setQuery] = useState("");
  const debounceValue = useDebounceValue(query);
  const [visiblePages, setVisiblePages] = useState([]);
  const [date, setDate] = useState('date');
  const [paided, setPaided] = useState('default');
  const [category, setCategory] = useState('all');
  
  const [userType, setUserType] = useState('users');




  //   const generateVisiblePages = () => {
  //     const interval = 3; // Nombre de pages avant et après la page actuelle à afficher
  //     let start = Math.max(1, page - interval);
  //     let end = Math.min(totalPages, page + interval);
  //     let pages = [];
  //     for (let i = start; i <= end; i++) {
  //         pages.push(i);
  //     }
  //     setVisiblePages(pages);
  // };

  const {selectedParticipants} = useSelector(participantSelector);


  const handleAction = () => {
    if(selectedParticipants.length <= 0){
      dispatch(openModal({
        type: 'SNACK-BAR',
        data: { severity: "warning", message: "Aucun élément selectionné" },
      }))
      return false;
    }
    dispatch(openActionMenuTab({type : "ACTION-MENU-TAB", data : {id : selectedParticipants }}));
  }


  

  const handleSelectAll = (users, isChecked) => {
    const allUserIds = users.map(user => user.id);
    if (isChecked) {
      dispatch(selectAllParticipants(allUserIds));
    }else{
      dispatch(deSelectAllParticipants());
    }
   

   
  };

  const nextPage = (userType) => {

    switch (userType) {
      case 'users' : {
        if (page < totalUsersPages) {
          setPage(page + 1);
          setUserType(userType);
        }
        break;
      }

      case 'waitingUsers' : {
        if (waitingPage < totalWaitingUsersPages) {
          setWaitingPage(waitingPage + 1);
          setUserType(userType);
        }
        break;
      }

      case 'validatedUsers' : {
        if (validatedPage < totalValidatedUsersPages) {
          setValidatedPage(validatedPage + 1);
          setUserType(userType);
        }
        break;
      }

      case 'rejectedUsers' : {
        if (rejectedPage < totalRejectedUsersPages) {
          setRejectedPage(rejectedPage + 1);
          setUserType(userType);
        }
        break;
      }

      case 'confirmedUsers' : {
        if (confirmedPage < totalConfirmedUsersPages) {
          setConfirmedPage(confirmedPage + 1);
          setUserType(userType);
        }
        break;
      }



      default : {

      }
  };
}

  const prevPage = (userType) => {
    console.log(userType);
    switch (userType) {
      case 'users' : {
        if (page > 1) {
          setPage(page - 1);
          setUserType(userType);
        }
        break;
      }

      case 'waitingUsers' : {
        if (waitingPage > 1) {
          setWaitingPage(waitingPage - 1);
          setUserType(userType);
        }
        break;
      }

      case 'validatedUsers' : {
        if (validatedPage > 1) {
          setValidatedPage(validatedPage - 1);
          setUserType(userType);
        }
        break;
      }

      case 'rejectedUsers' : {
        if (rejectedPage > 1) {
          setRejectedPage(rejectedPage - 1);
          setUserType(userType);
        }
        break;
      }

      case 'confirmedUsers' : {
        if (confirmedPage > 1) {
          setConfirmedPage(confirmedPage - 1);
          setUserType(userType);
        }
        break;
      }


      default : {

      }
  };
}

  const changePage = (pageNumber, userType) => {
    switch (userType) {
      case 'users' : {
        setPage(pageNumber);
        break;
      }

      case 'waitingUsers' : {
        setWaitingPage(pageNumber);
        break;
      }

      case 'validatedUsers' : {
        setValidatedPage(pageNumber);
        break;
      }

      case 'rejectedUsers' : {
        setRejectedPage(pageNumber);
        break;
      }

      case 'confirmedUsers' : {
        setConfirmedPage(pageNumber);
        break;
      }

      default : {

      }
    }
  
  };

  const renderPageButtons = (totalUsersPages, userType, page) => {
    let buttons = [];
    for (let i = 1; i <= totalUsersPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => changePage(i, userType)}
          disabled={i === page}
          className="btn me-2"
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  function useDebounceValue(value, time = 100) {
    const [debounceValue, setDebounceValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebounceValue(value);
      }, time);
      return () => {
        clearTimeout(timer);
      };
    }, [value, time]);

    return debounceValue;
  }

  const handleExportCsv = (exportType) => {
 

    if(selectedParticipants.length > 0){
      exportType = 'selected'
    }

    console.log(userType);
  
    let dataToExport = [];
    switch (exportType) {
      case 'selected':
        switch (userType) {
          case 'users' : {
            dataToExport = users.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'waitingUsers' : {
            dataToExport = waitingUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'validatedUsers' : {
            dataToExport = validatedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          default : {
    
          }
    
        }
        break;
      case 'filtered':
        switch (userType) {
          case 'users' : {
            dataToExport = users.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'waitingUsers' : {
            dataToExport = waitingUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'validatedUsers' : {
            dataToExport = validatedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          default : {
    
          }
    
        }
        break;
      default:
        switch (userType) {
          case 'users' : {
            dataToExport = users;
            break;
          }
    
          case 'waitingUsers' : {
            dataToExport = waitingUsers;
            break;
          }
    
          case 'validatedUsers' : {
            dataToExport = validatedUsers;
            break;
          }
    
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers;
            break;
          }
    
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers;
            break;
          }
    
          default : {
    
          }
    
        }
    }

   

    console.log(dataToExport);

    const opts = {};
    const parser = new Parser(opts);
    const csvData = parser.parse(dataToExport);
  
    console.log(csvData);

    // Logique pour l'export (par exemple, téléchargement du fichier CSV)
    // Exemple de téléchargement de fichier CSV
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported_users.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    dispatch(deSelectAllParticipants());
  }

  const handleExportXslx = (exportType) => {
    // Convertir les données à exporter dans le format souhaité (XLSX)

    if(selectedParticipants.length > 0){
      exportType = 'selected'
    }
  
    let dataToExport = [];
    switch (exportType) {
      case 'selected':
        switch (userType) {
          case 'users' : {
            dataToExport = users.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'waitingUsers' : {
            dataToExport = waitingUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'validatedUsers' : {
            dataToExport = validatedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
    
          default : {
    
          }
    
        }
        break;
      case 'filtered':
        switch (userType) {
          case 'users' : {
            dataToExport = users.filter(user => selectedParticipants.includes(user.id));
            break;
          }
          case 'waitingUsers' : {
            dataToExport = waitingUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
          case 'validatedUsers' : {
            dataToExport = validatedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers.filter(user => selectedParticipants.includes(user.id));
            break;
          }
          default : {
    
          }
        }
        break;
      default:
        switch (userType) {
          case 'users' : {
            dataToExport = users;
            break;
          }
    
          case 'waitingUsers' : {
            dataToExport = waitingUsers;
            break;
          }
    
          case 'validatedUsers' : {
            dataToExport = validatedUsers;
            break;
          }
    
          case 'rejectedUsers' : {
            dataToExport = rejectedUsers;
            break;
          }
    
          case 'confirmedUsers' : {
            dataToExport = confirmedUsers;
            break;
          }
    
          default : {
    
          }
    
        }
    }


    const worksheet = utils.json_to_sheet(dataToExport);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Users');
    // Logique pour l'export (par exemple, téléchargement du fichier XLSX)
    writeFile(workbook, 'exported_users.xlsx');
    dispatch(deSelectAllParticipants());
  }

  useEffect(() => {

    switch(userType) {
      case 'users' : {
        dispatch(searchUserThunk({ paided : paided, query: debounceValue, token: state.adminToken, page: page, date : date, category: category  }));
        break;
      }
      case 'waitingUsers' : {
        dispatch(searchWaitingUserThunk({paided : paided, query: debounceValue, token: state.adminToken, page: waitingPage, date : date, category: category }));
        break;
      }

      case 'validatedUsers' : {
        dispatch(searchValidatedUserThunk({ paided : paided, query: debounceValue, token: state.adminToken, page: validatedPage, date : date, category: category }));
        break;
      }

      case 'rejectedUsers' : {
        dispatch(searchRejectedUserThunk({paided : paided,  query: debounceValue, token: state.adminToken, page: rejectedPage, date : date, category: category }));
        break;
      }

      
      case 'confirmedUsers' : {
        dispatch(searchConfirmedUserThunk({paided : paided, query: debounceValue, token: state.adminToken, page: confirmedPage, date : date, category: category }));
        break;
      }


      default : break;
    }
  }, [debounceValue, page,waitingPage,validatedPage,rejectedPage,confirmedPage, date,paided, userType, category]);


  useEffect(() => {
        dispatch(searchUserThunk({ query: debounceValue, token: state.adminToken, page: page, date : date , paided : paided }));
    }, []);

  return (
    <div className="backC">
      <Sidebar />
      <main className="main-content main-nelly">
        <AdminNav title="Total Participants " />
        <div>
          <SidebarResponseClose />
          <div className="tab--top ">
            <div className="container">
              <div className="d-flex align-items-center justify-content-between mb-5">
                <h3>participants</h3>
                <ul
                  className="nav nav-pills nav-pills1 mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      onClick={() => setUserType('users')}
                    >
                      Tout
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                      onClick={()=> setUserType('waitingUsers')}
                    >
                      En attente{" "}
                      <span className="badge rounded-pill text-bg-blue-light">
                        {/* {!!users &&
                          users.filter((u) => u.status === "WAITING").length} */}
                          {totalWaitingUsersCount}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                      onClick={() => setUserType('validatedUsers')}
                    >
                      Approuvés{" "}
                      <span className="badge rounded-pill text-bg-green-light">
                        {/* {!!users &&
                          users.filter((u) => u.status === "APPROVED").length} */}
                          {totalValidatedUsersCount}
                      </span>
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-validated-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-validated"
                      type="button"
                      role="tab"
                      aria-controls="pills-validated"
                      aria-selected="false"
                      onClick={() => setUserType('confirmedUsers')}
                    >
                      Confirmés{" "}
                      <span className="badge rounded-pill text-bg-green">
                        {/* {!!users &&
                          users.filter((u) => u.status === "APPROVED").length} */}
                          {totalConfirmedUsersCount}
                      </span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-disabled-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-disabled"
                      type="button"
                      role="tab"
                      aria-controls="pills-disabled"
                      aria-selected="false"
                      onClick={() => setUserType('rejectedUsers')}
                    >
                      Rejetés{" "}
                      <span className="badge rounded-pill text-bg-red-light">
                        {/* {!!users &&
                          users.filter((u) => u.status === "REVOKED").length} */}

                          {totalRevokedUsersCount}
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div className="tableDashtop">
                  <div className="d-flex">
                    <div className="input--group1 mb-0">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        placeholder="Rechercher"
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>

                    <div className="input--group mb-0">
                      <select name="" id="" onChange={(e) => setDate(e.target.value)}>
                      <option value="date">
                          Trier par : Date
                        </option>
                        <option value="today">
                          Trier par :  Aujourd'hui
                        </option>
                      </select>
                    </div>

                    <div className="input--group mb-0">
                      <select name="" id="" onChange={(e) => setCategory(e.target.value)}>
                      <option value="all">
                          Trier par : Categorie
                        </option>
                        <option value="PARTICIPANT">
                          PARTICIPANT
                        </option>

                        <option value="VIP">
                          VIP
                        </option>

                        <option value="PRESSE">
                          PRESSE
                        </option>

                        <option value="CAW">
                          CYBER AFRICA WOMEN
                        </option>

                        <option value="ORGANISATION">
                          ORGANISATION
                        </option>

                        <option value="GUEST">
                          GUEST
                        </option>

                        <option value="SPEAKER">
                          SPEAKER
                        </option>

                        <option value="OFFICIEL">
                          OFFICIEL
                        </option>

                        <option value="PARTENAIRE">
                          PARTENAIRE
                        </option>

                        <option value="SPONSOR">
                          SPONSOR
                        </option>

                        <option value="MEDIA">
                          MEDIA
                        </option>



                      </select>
                    </div>


                    <div className="input--group mb-0">
                      <select name="" id="" onChange={(e) => setPaided(e.target.value)}>
                      <option value="default">
                          Trier par : Defaut
                        </option>
                        <option value="paided">
                          Trier par :  Payés
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <button className="btn--tr me-2" onClick={handleAction}>
                        <i className="fas fa-ellipsis-h"></i> Action
                    </button>
                    {/* <button className="btn--tr me-2">
                      <i className="fas fa-bell"></i> Envoyer une notification
                    </button>
                    <button className="btn--tr me-2">
                      <i className="fas fa-envelope-open-text"></i> Envoyer un
                      document
                    </button>
                    <button className="btn--tr me-2">
                      <i className="fas fa-upload"></i> Importer
                    </button> */}
                    <button className="btn--tr me-2" onClick={handleExportCsv}>
                      <i className="fas fa-download"></i> Exporter Csv
                    </button>

                    <button className="btn--tr me-2" onClick={handleExportXslx}>
                      <i className="fas fa-download"></i> Exporter XSLX
                    </button>
                  </div>
                  <div>
                    {/* <button className="btn--site">
                      <i className="fas fa-plus"></i> Ajouter
                    </button> */}
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                  tabIndex="0"
                >
                  <div className="tableDash">
                    <div className="ov--table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" onClick={(e) => handleSelectAll(users,e.target.checked)}  />
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            
                            <th scope="col">Organisation</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Payé</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                        {/* && users.length > 0 à ajouter */}
                          {(!!users && !loading )? (
                            users.map((user) => {
                              return (
                                <ParticipantRow key={user.id} user={user} users={users}/>
                              );
                            })
                          ) : (
                            <ParticipantLoader />
                          )}
                        </tbody>
                      </table>

                      <ActionMenuTab />

                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => prevPage("users")}
                          disabled={page === 1}
                          className="me-2 btn"
                        >
                          Previous
                        </button>
                        {renderPageButtons(totalUsersPages, 'users', page)}
                        {/* {visiblePages.map(pageNumber => (
                            <button key={pageNumber} onClick={() => changePage(pageNumber)} disabled={pageNumber === page}>
                                {pageNumber}
                            </button>
                        ))} */}
                        <button
                          onClick={() => nextPage('users')}
                          disabled={page === totalUsersPages}
                          className="btn"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                  tabIndex="0"
                >
                  <div className="tableDash">
                    <div className="ov--table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" onClick={(e) => handleSelectAll(waitingUsers, e.target.checked)}/>
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            <th scope="col">Organisation</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Payé</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            !!waitingUsers &&
                            waitingUsers
                              .map((user) => {
                                return (
                                  <ParticipantRow key={user.id} user={user} />
                                );
                              })
                          ) : (
                            <ParticipantLoader />
                          )}
                        </tbody>
                      </table>

                      <ActionMenuTab />

                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => prevPage("waitingUsers")}
                          disabled={waitingPage === 1}
                          className="me-2 btn"
                        >
                          Previous
                        </button>
                        {renderPageButtons(totalWaitingUsersPages, 'waitingUsers', waitingPage)}
                        {/* {visiblePages.map(pageNumber => (
                            <button key={pageNumber} onClick={() => changePage(pageNumber)} disabled={pageNumber === page}>
                                {pageNumber}
                            </button>
                        ))} */}
                        <button
                          onClick={() => nextPage('waitingUsers')}
                          disabled={waitingPage === totalWaitingUsersPages}
                          className="btn"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-contact"
                  role="tabpanel"
                  aria-labelledby="pills-contact-tab"
                  tabIndex="0"
                >
                  <div className="tableDash">
                    <div className="ov--table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" onClick={(e) => handleSelectAll(validatedUsers, e.target.checked)}/>
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            <th scope="col">Organisation</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Payé</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            !!validatedUsers &&
                            validatedUsers
                              .map((user) => {
                                return (
                                  <ParticipantRow key={user.id} user={user} />
                                );
                              })
                          ) : (
                            <ParticipantLoader />
                          )}
                        </tbody>
                      </table>

                      <ActionMenuTab />

                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => prevPage("validatedUsers")}
                          disabled={validatedPage === 1}
                          className="me-2 btn"
                        >
                          Previous
                        </button>
                        {renderPageButtons(totalValidatedUsersPages, 'validatedUsers', validatedPage)}
                        {/* {visiblePages.map(pageNumber => (
                            <button key={pageNumber} onClick={() => changePage(pageNumber)} disabled={pageNumber === page}>
                                {pageNumber}
                            </button>
                        ))} */}
                        <button
                          onClick={() => nextPage('validatedUsers')}
                          disabled={validatedPage === totalValidatedUsersPages}
                          className="btn"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className="tab-pane fade"
                  id="pills-validated"
                  role="tabpanel"
                  aria-labelledby="pills-validated-tab"
                  tabIndex="0"
                >
                  <div className="tableDash">
                    <div className="ov--table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" onClick={(e) => handleSelectAll(confirmedUsers, e.target.checked)}/>
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            <th scope="col">Organisation</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Payé</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            !!confirmedUsers &&
                            confirmedUsers
                              .map((user) => {
                                return (
                                  <ParticipantRow key={user.id} user={user} />
                                );
                              })
                          ) : (
                            <ParticipantLoader />
                          )}
                        </tbody>
                      </table>

                      <ActionMenuTab />

                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => prevPage("confirmedUsers")}
                          disabled={confirmedPage === 1}
                          className="me-2 btn"
                        >
                          Previous
                        </button>
                        {renderPageButtons(totalConfirmedUsersPages, 'confirmedUsers', confirmedPage)}
                        {/* {visiblePages.map(pageNumber => (
                            <button key={pageNumber} onClick={() => changePage(pageNumber)} disabled={pageNumber === page}>
                                {pageNumber}
                            </button>
                        ))} */}
                        <button
                          onClick={() => nextPage('validatedUsers')}
                          disabled={confirmedPage === totalConfirmedUsersPages}
                          className="btn"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-disabled"
                  role="tabpanel"
                  aria-labelledby="pills-disabled-tab"
                  tabIndex="0"
                >
                  <div className="tableDash">
                    <div className="ov--table">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <input type="checkbox" name="" id="" onClick={(e) => handleSelectAll(rejectedUsers, e.target.checked)}/>
                            </th>
                            <th scope="col">Identifiant</th>
                            <th scope="col">Date d'inscription</th>
                            <th scope="col">Type</th>
                            <th scope="col">Nom & prenom</th>
                            <th scope="col">Fonction</th>
                            <th scope="col">Organisation</th>
                            <th scope="col">Email</th>
                            <th scope="col">Téléphone</th>
                            <th scope="col">Statut</th>
                            <th scope="col">Payé</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading ? (
                            !!rejectedUsers &&
                            rejectedUsers
                              .map((user) => {
                                return (
                                  <ParticipantRow key={user.id} user={user} />
                                );
                              })
                          ) : (
                            <ParticipantLoader />
                          )}
                        </tbody>
                      </table>

                      <ActionMenuTab />

                      <div className="d-flex justify-content-center">
                        <button
                          onClick={() => prevPage('rejectedUsers')}
                          disabled={rejectedPage === 1}
                          className="me-2 btn"
                        >
                          Previous
                        </button>
                        {renderPageButtons(totalRejectedUsersPages, 'rejectedUsers', rejectedPage)}
                        {/* {visiblePages.map(pageNumber => (
                            <button key={pageNumber} onClick={() => changePage(pageNumber)} disabled={pageNumber === page}>
                                {pageNumber}
                            </button>
                        ))} */}
                        <button
                          onClick={() => nextPage('rejectedUsers')}
                          disabled={rejectedPage === totalRejectedUsersPages}
                          className="btn"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AdminParticipant;
