import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './admin/AdminMain.css';
import {
  adminLoginThunk,
  loginInitiateThunk,
  loginSelector,
  loginThunk,
  logout,
} from "../redux/slices/authSlice";
import { useAuth } from "../context/AuthContext";
import { openModal } from "../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";
import { getItemFromLocalStorage } from "../features/helpers";

function Login() {
  const [email, setEmail] = useState(getItemFromLocalStorage('email',""));
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [otpSection, setOtpSection] = useState(getItemFromLocalStorage('otpSection',false));
  const { loading } = useSelector(loginSelector);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpLengthValid, setOtpLengthValid] = useState(false);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateOtp = (otp) => {
    setOtpLengthValid(otp.length === 6);
  };

 

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailError("Le champs email ne peut être vide.");
      return;
    }
   
    const data = {
      email: email,
      password: password,
    };
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    }

    
 
    dispatch(loginInitiateThunk(data)).then((data) => {
      if (data.status === 201 || data.status === 200) {
        setOtpSection(true);
        localStorage.setItem("otpSection", true);
        localStorage.setItem("email", email);
      } else {
        console.log(data);
        dispatch(
          openModal({
            type: "SNACK-BAR",
            data: { severity: "error", message: data.data.error },
          })
        );
      }
    });

  };

  const handleResendOtp = (data) => {
    dispatch(
        openModal({
          type: "SNACK-BAR",
          data: { severity: "success", message: 'Disponible bientot' },
        })
      );
  }


  const handleGoBack = (data) => {
    localStorage.removeItem("otpSection");
    setOtpSection(false);

    dispatch(
        openModal({
          type: "SNACK-BAR",
          data: { severity: "success", message: 'Disponible bientot' },
        })
      );
  }



  const handleSubmitOtp = (e) => {
    e.preventDefault();
    if (otp === "") {
        setOtpError("Le champs otp ne peut être vide.");
        return;
      }

    
    if (!otpLengthValid) {
      setOtpError("Le champs otp doit être de 6 caractères.");
      return;
    }

      const data = {
        verificationCode : otp,
        email : email,
        fromWebsite : true,
        notificationToken : "dciueji,os"
      }

      dispatch(loginThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          setOtpSection(false);
          localStorage.setItem("otpSection", false);
          setOtp("");
          setOtpError('');
          navigate("/home");
        } else {
          setOtpError("");
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.error },
            })
          );
        }
      });
  }
  return (
<>
{/* <Header title="Espace Administrateur"/> */}
<div class="header-1">
        <div class="container">
            <h1>Espace Participant</h1>
        </div>
    </div>
<main>
<div class="part--connexion">
  <div class="part--connexion__left">
    <div class="form--login">
        
      <form onSubmit={otpSection ? handleSubmitOtp : handleSubmit}>
      <h1>Connexion</h1>

      <div class="form--login__part">
        {!otpSection ?  <div class="input--group">
          <label for="">
            Adresse mail <span>*</span>
          </label>
          <input type="text"          
          value={email}
          onChange={(e) => setEmail(e.target.value)}/>

        {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        </div> :  <div class="input--group">
          <label for="">
           Verifier le code otp envoyé par email <span>*</span>
          </label>
          <input type="text"          
          value={otp}
          onChange={(e) => {
          setOtp(e.target.value); 
          validateOtp(e.target.value)
          ;}}/>

        {otpError && <p style={{ color: "red" }}>{otpError}</p>}

     <div className="d-flex justify-content-between resend-otp" >
        <p onClick={handleGoBack}>Retour</p>
        <p onClick={handleResendOtp}>Resend</p>
     </div>
        </div> }
       

      

        <div class="text-center mt-5 mb-5">
     
         {otpSection ? <button type="submit" class="btn--site" onClick={handleSubmitOtp}>
              {loading ? 'Loading...' : "Send OTP "}
            </button> : <button type="submit" class="btn--site" onClick={handleSubmit}>
              {loading ? 'Loading...' : "Me connecter "}
            </button>}
            

         
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
</main>
</>
  );
}

export default Login;
