// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

//mettre ça dans user apres 
const initialState = {
  selectedParticipants: [],
};

const participantSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    selectParticipant(state, action) {
      state.selectedParticipants.push(action.payload);
    },
    deselectParticipant(state, action) {
      state.selectedParticipants = state.selectedParticipants.filter(userId => userId !== action.payload);
    },
    selectAllParticipants(state, action) {
      state.selectedParticipants = action.payload;
    },
    deSelectAllParticipants(state) {
        state.selectedParticipants = [];
    },
  },
});

export const { deSelectAllParticipants, selectParticipant, deselectParticipant, selectAllParticipants } = participantSlice.actions;
export const participantSelector = (state) => state.participant;
export default participantSlice.reducer;
