import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: false,
  hasErrors: false,
  errMsg: "",
  users: [],
  totalUsersPages: 1,
  totalWaitingUsersPages: 1,
  totalConfirmedUsersPages: 1,
  totalRejectedUsersPages: 1,
  totalValidatedUsersPages: 1,
  totalUsersCount: 0,
  totalUsersVipCount: 0,
  totalWomenUsersCount: 0,
  totalUsersParticipantCount: 0,
  totalWaitingUsersCount: 0,
  totalConfirmedUsersCount: 0,
  totalValidatedUsersCount: 0,
  totalRevokedUsersCount: 0,
  totalPaidedUsersCount : 0,
  selectedUser: {},
  waitingUsers: [],
  validatedUsers: [],
  rejectedUsers: [],
  confirmedUsers : [],
  loadingMenu: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    update(state, { payload }) {
      state.loading = true;
    },
    updateSuccess(state, { payload }) {
      state.loading = false;
      const userToUpdate = state.users.find(
        (user) => user.id === payload.data.id
      );
      if (userToUpdate) {
        userToUpdate.status = payload.data.status;
        userToUpdate.category = payload.data.category;
        //eventuellement tous les champs necessaires
      }

      const waitingUserToUpdate = state.waitingUsers.find(
        (user) => user.id === payload.data.id
      );
      if (waitingUserToUpdate) {
        waitingUserToUpdate.status = payload.data.status;
        waitingUserToUpdate.category = payload.data.category;
        //eventuellement tous les champs necessaires
      }

      const approvedUserToUpdate = state.validatedUsers.find(
        (user) => user.id === payload.data.id
      );
      if (approvedUserToUpdate) {
        approvedUserToUpdate.status = payload.data.status;
        approvedUserToUpdate.category = payload.data.category;
        //eventuellement tous les champs necessaires
      }

      const rejectedUserToUpdate = state.rejectedUsers.find(
        (user) => user.id === payload.data.id
      );
      if (rejectedUserToUpdate) {
        rejectedUserToUpdate.status = payload.data.status;
        rejectedUserToUpdate.category = payload.data.category;
        //eventuellement tous les champs necessaires
      }


      const confirmedUserToUpdate = state.confirmedUsers.find(
        (user) => user.id === payload.data.id
      );
      if (confirmedUserToUpdate) {
        confirmedUserToUpdate.status = payload.data.status;
        confirmedUserToUpdate.category = payload.data.category;
        //eventuellement tous les champs necessaires
      }

      // localStorage.setItem('user', JSON.stringify(payload.data));
    },

    updateSelectedSuccess(state, { payload }) {
      state.loading = false;
      payload.data.forEach((u) => {
        const userToUpdate = state.users.find((user) => user.id === u.id);
        if (userToUpdate) {
          userToUpdate.status = u.status;
          userToUpdate.category = u.category;
        }
      });

      payload.data.forEach((u) => {
        const userToUpdate = state.waitingUsers.find(
          (user) => user.id === u.id
        );
        if (userToUpdate) {
          userToUpdate.status = u.status;
          userToUpdate.category = u.category;
        }
      });

      payload.data.forEach((u) => {
        const userToUpdate = state.validatedUsers.find(
          (user) => user.id === u.id
        );
        if (userToUpdate) {
          userToUpdate.status = u.status;
          userToUpdate.category = u.category;
        }
      });

      payload.data.forEach((u) => {
        const userToUpdate = state.rejectedUsers.find(
          (user) => user.id === u.id
        );
        if (userToUpdate) {
          userToUpdate.status = u.status;
          userToUpdate.category = u.category;
        }
      });


      payload.data.forEach((u) => {
        const userToUpdate = state.confirmedUsers.find(
          (user) => user.id === u.id
        );
        if (userToUpdate) {
          userToUpdate.status = u.status;
          userToUpdate.category = u.category;
        }
      });
    },
    updateFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    searchUser(state, { payload }) {
      state.loading = true;
    },
    searchUserSuccess(state, { payload }) {
      console.log(payload.data);
      state.users = payload.data.data;
      state.totalUsersPages = payload.data.last_page;
      state.loading = false;
    },

    searchUserFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    searchRejectedUsers(state, { payload }) {
      state.loading = true;
    },

    searchRejectedUsersSuccess(state, { payload }) {
      console.log(payload.data);
      state.rejectedUsers = payload.data.data;
      state.totalRejectedUsersPages = payload.data.last_page;
      state.loading = false;
    },

    searchRejectedUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    searchValidatedUsers(state, { payload }) {
      state.loading = true;
    },

    searchValidatedUsersSuccess(state, { payload }) {
      console.log(payload.data);
      state.validatedUsers = payload.data.data;
      state.totalValidatedUsersPages = payload.data.last_page;
      state.loading = false;
    },

    searchValidatedUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    searchWaitingUsers(state, { payload }) {
      state.loading = true;
    },

    searchWaitingUsersSuccess(state, { payload }) {
      console.log(payload.data);
      state.waitingUsers = payload.data.data;
      state.totalWaitingUsersPages = payload.data.last_page;
      state.loading = false;
    },

    searchWaitingUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    searchConfirmedUsers(state, { payload }) {
      state.loading = true;
    },

    searchConfirmedUsersSuccess(state, { payload }) {
      console.log(payload.data);
      state.confirmedUsers = payload.data.data;
      state.totalConfirmedUsersPages = payload.data.last_page;
      state.loading = false;
    },

    searchConfirmedUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

   

    deleteUser(state, { payload }) {
      state.loading = true;
    },
    deleteUserSuccess(state, { payload }) {
      state.loading = false;
      state.users = state.users.filter((user) => user.id !== payload.data.id);
    },
    deleteUserFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    getUsers(state, action) {
      state.loading = true;
    },
    getUsersSuccess(state, { payload }) {
      state.loading = false;
      state.users = payload.data.data;
      state.totalPages = payload.data.last_page;
    },
    getUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    getTotalUsers(state, action) {
      state.loading = true;
    },
    getTotalUsersSuccess(state, { payload }) {
      state.loading = false;
      state.totalUsersCount = payload.data.totalUsers;
      state.totalUsersVipCount = payload.data.totalVipUsers;
      state.totalUsersParticipantCount = payload.data.totalParticipantUsers;
      state.totalWaitingUsersCount = payload.data.totalWaitingUsers;
      state.totalValidatedUsersCount = payload.data.totalValidatedUsers;
      state.totalRevokedUsersCount = payload.data.totalRevokedUsers;
      state.totalConfirmedUsersCount = payload.data.totalConfirmedUsers;
      state.totalWomenUsersCount = payload.data.totalWomenUsers;
      state.totalPaidedUsersCount = payload.data.totalPaidedUsers;
    },
    getTotalUsersFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure update");
    },

    downloadBadge(state, { payload }) {
      state.loading = true;
    },

    downloadBadgeSuccess(state, { payload }) {
      state.loading = false;
    },

    downloadBadgeFailure(state, { payload }) {
      state.loading = false;
      state.hasErrors = true;
      state.errMsg = payload;
      console.log("failure downloading badge");
    },

    selectUser(state, { payload }) {
      const userToUpdate = state.users.find((user) => user.id === payload);
      state.selectedUser = userToUpdate;
    },

    selectUsers(state, { payload }) {
      
      
    },
  },
});

export const {
  searchConfirmedUsers,
  searchConfirmedUsersSuccess,
  searchConfirmedUsersFailure,
  searchRejectedUsers,
  searchRejectedUsersSuccess,
  searchRejectedUsersFailure,
  searchValidatedUsers,
  searchValidatedUsersSuccess,
  searchValidatedUsersFailure,
  searchWaitingUsersFailure,
  searchWaitingUsers,
  searchWaitingUsersSuccess,
  downloadBadge,
  downloadBadgeSuccess,
  downloadBadgeFailure,
  selectUser,
  updateSelectedSuccess,
  getTotalUsers,
  getTotalUsersSuccess,
  getTotalUsersFailure,
  searchUser,
  searchUserSuccess,
  searchUserFailure,
  updateSuccess,
  update,
  updateFailure,
  getUsersSuccess,
  getUsers,
  getUsersFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
} = userSlice.actions;
export const userSelector = (state) => state.user;

export const updateUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/users`,
        data,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const adminUpdateUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/update`,
        data,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};


export const adminUpdateSelectedUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/update`,
        data,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const approveUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/approve`,
        {
          status: data.status,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const approveUserWithDiscountThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/approve/with-discount`,
        {
          has_discount: true,
          discount_percentage: 15,
          status: "APPROVED",
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const sendDsiMailThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/dsi`,
        {
          status: "APPROVED",
          category : "GUEST"
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send mail dsi failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const sendInvitedMailThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/invited`,
        {
          status: "APPROVED",
          category : "GUEST"
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send mail dsi failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};




export const sendBadgeMailThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/badge`,
        {
          status: "CONFIRMED",
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send mail badge failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};


export const sendPaymentReminderMailThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/payment-reminder`,
        {
          status: "APPROVED",
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send mail payment reminder failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};


export const declineUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/users/decline`,
        {
          status: data.status,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const approveSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/approve`,
        {
          status: data.status,
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const approveSelectedUsersWithDiscountThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/approve/with-discount`,
        {
          has_discount: true,
          discount_percentage: 15,
          status: data.status,
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const declineSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/decline`,
        {
          status: data.status,
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const sendDsiMailSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/dsi`,
        {
          status: "APPROVED",
          id: data.id,
          category : "GUEST"
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send dis mail failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const sendInvitedMailSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/invited`,
        {
          status: "APPROVED",
          id: data.id,
          category : "GUEST"
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send dis mail failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};



export const sendBadgeMailSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/badge`,
        {
          status: "CONFIRMED",
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send dis mail failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};


export const sendPaymentReminderMailSelectedUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(update());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/payment-reminder`,
        {
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(updateSelectedSuccess(response.data));
      return response;
    } catch (error) {
      console.error("send payment reminder mail failed:", error);
      dispatch(updateFailure(error.message));
      return error.response;
    }
  };
};

export const searchUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(searchUser());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users/search?page=${data.page}`,
        {
          query: data.query,
          filterByDate: data.date,
          category : data.category,
          filterByPaided : data.paided,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );

      // console.log(response.data);
      dispatch(searchUserSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Searching failed:", error.message);
      dispatch(searchUserFailure(error.message));
      return error.response;
    }
  };
};

export const searchWaitingUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(searchWaitingUsers());
    try {
      let page = data.page;

      if(data.query !== ""){
        page = 1;
      }


      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users/search/waiting?page=${page}`,
        {
          query: data.query,
          filterByDate: data.date,
          category : data.category,
          filterByPaided : data.paided,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );

      // console.log(response.data);
      dispatch(searchWaitingUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Searching waiting users failed:", error.message);
      dispatch(searchWaitingUsersFailure(error.message));
      return error.response;
    }
  };
};



export const searchConfirmedUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(searchConfirmedUsers());
    try {
      let page = data.page;

      if(data.query !== ""){
        page = 1;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users/search/confirmed?page=${page}`,
        {
          query: data.query,
          filterByDate: data.date,
          category : data.category,
          filterByPaided : data.paided,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );

      // console.log(response.data);
      dispatch(searchConfirmedUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Searching confirmed users failed:", error.message);
      dispatch(searchConfirmedUsersFailure(error.message));
      return error.response;
    }
  };
};



export const searchValidatedUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(searchValidatedUsers());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users/search/validated?page=${data.page}`,
        {
          query: data.query,
          filterByDate: data.date,
          category : data.category,
          filterByPaided : data.paided,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );

      // console.log(response.data);
      dispatch(searchValidatedUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Searching validated users failed:", error.message);
      dispatch(searchValidatedUsersFailure(error.message));
      return error.response;
    }
  };
};

export const searchRejectedUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(searchRejectedUsers());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users/search/rejected?page=${data.page}`,
        {
          query: data.query,
          filterByDate: data.date,
          category : data.category,
          filterByPaided : data.paided,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );

      // console.log(response.data);
      dispatch(searchRejectedUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Searching validated users failed:", error.message);
      dispatch(searchRejectedUsersFailure(error.message));
      return error.response;
    }
  };
};

export const deleteUserThunk = (data) => {
  return async (dispatch) => {
    dispatch(deleteUser());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/delete/users/${data.id}`,
        {
          status: data.status,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(deleteUserSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(deleteUserFailure(error.message));
      return error.response;
    }
  };
};

export const getUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(getUsers());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/100/users?page=${data.page}`,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(getUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("Update failed:", error);
      dispatch(getUsersFailure(error.message));
      return error.response;
    }
  };
};

export const getTotalUsersThunk = (data) => {
  return async (dispatch) => {
    dispatch(getTotalUsers());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/users`,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
        }
      );
      dispatch(getTotalUsersSuccess(response.data));
      return response;
    } catch (error) {
      console.error("get total count failed:", error.message);
      dispatch(getTotalUsersFailure(error.message));
      return error.response;
    }
  };
};

export const adminDownloadBadgeThunk = (data) => {
  return async (dispatch) => {
    dispatch(downloadBadge());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/${data.id}/generate-badge-pdf`,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
          responseType: "blob",
        }
      );
      dispatch(downloadBadgeSuccess(response.data));
      return response;
    } catch (error) {
      console.error("get badge failed:", error.message);
      dispatch(downloadBadgeFailure(error.message));
      return error.response;
    }
  };
};

export const adminDownloadSelectedBadgeThunk = (data) => {
  return async (dispatch) => {
    dispatch(downloadBadge());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/selected-users/generate-badge-pdf`,
        {
          id: data.id,
        },
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
          responseType: "blob",
        }
      );
      dispatch(downloadBadgeSuccess(response.data));
      return response;
    } catch (error) {
      console.error("get badge failed:", error.message);
      dispatch(downloadBadgeFailure(error.message));
      return error.response;
    }
  };
};

export const downloadBadgeThunk = (data) => {
  return async (dispatch) => {
    dispatch(downloadBadge());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/generate-badge-pdf`,
        {
          headers: {
            authorization: "Bearer " + data.token,
          },
          responseType: "blob",
        }
      );
      dispatch(downloadBadgeSuccess(response.data));
      return response;
    } catch (error) {
      console.error("get badge failed:", error.message);
      dispatch(downloadBadgeFailure(error.message));
      return error.response;
    }
  };
};

export default userSlice.reducer;
