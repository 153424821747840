import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout, logoutAdmin } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar, utilSelector } from "../../redux/slices/utilSlice";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, modalType } = useSelector(utilSelector);

  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname ? "active--nav " : "";
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logoutAdmin());
    navigate("/admin/login");
  };

  return (
    <div
      className={
        isOpen && modalType === "SIDEBAR"
          ? "sidebar sidebar--active"
          : "sidebar"
      }
    >
      <div>
        <div className="logo-top ">
          <Link to="/admin">
            <div className="logoBox ">
              <img
                src="https://www.cyberafricaforum.com/new-caf/logo/logo.png?ver=1"
                alt="mage-logo"
                className="image-logo"
              />
            </div>
          </Link>
          <div
            className="close-menu"
            id="sidebarResponsive"
            onClick={() => dispatch(closeSidebar({ type: "SIDEBAR" }))}
          >
            <i className="fas fa-times " style={{ color: "white" }}></i>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div className="sep"></div>
        </div>

        <div className="sidebar-menu mt-5">
          <h3>Espace administrateur</h3>
          <ul className="sidebar-card">
            <li className="sidebar-item">
              <Link
                to="/admin"
                className={isActive("/admin") + "d-flex align-items-center"}
              >
                <div className="icon-menu">
                  <title>home</title>
                  <img src="/icones/home.svg" alt="" />
                </div>
                <span>Accueil</span>
              </Link>
            </li>

            <li>
              <Link
                to="/admin/participants"
                className={
                  isActive("/admin/participants") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Participants</title>
                  <img src="/icones/users.svg" alt="" />
                </div>
                <span>Participants</span>
              </Link>
            </li>
{/* 
            <li>
              <Link
                to="/admin/partners"
                className={
                  isActive("/admin/partners") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Partenaires</title>
                  <img src="/icones/partenaires.svg" alt="" />
                </div>
                <span>Partenaires</span>
              </Link>
            </li> */}

            <li>
              <Link
                to="/admin/config"
                className={
                  isActive("/admin/config") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Email</title>
                  <img src="/icones/reademailalt.svg" alt="" />
                </div>
                <span>Configuration email</span>
              </Link>
            </li>

            {/* <li>
              <Link
                to="/admin/form"
                className={
                  isActive("/admin/form") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Formulaire</title>
                  <img src="/icones/document.svg" alt="" />
                </div>
                <span>Formulaire</span>
              </Link>
            </li> */}

            <li>
              <Link
                to="/admin/payment"
                className={
                  isActive("/admin/payment") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Paiements</title>
                  <img src="/icones/card.svg" alt="" />
                </div>
                <span>Paiements</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="button--deconnexion" onClick={handleLogout}>
        <a href="#e">
          <i className="fas fa-sign-out-alt "></i> &nbsp; Déconnexion
        </a>
      </div>
    </div>
  );
}

export default Sidebar;
