import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PaymentPage from "./pages/PaymentPage";
import SnackBarComponent from "./components/SnackBarComponent";
import Badge from "./components/badge/Badge";
import Mail from "./components/mail/Mail";
import BadgePdf from "./components/badge/BadgePdf";
import UsersList from "./components/UserList";
import LoginAdmin from "./pages/LoginAdmin";
import { useSelector } from "react-redux";
import { loginSelector } from "./redux/slices/authSlice";
import AdminHome from "./pages/admin/AdminHome";
import AdminParticipant from "./pages/admin/AdminParticipant";
import NotFound from "./pages/NotFound";
import AdminConfigEmail from "./pages/admin/AdminConfigEmail";
import Login from "./pages/Login";
import ParticipantHome from "./pages/participant/ParticipantHome";
import AdminPartners from "./pages/admin/AdminPartners";
import AdminForm from "./pages/admin/AdminForm";
import AdminPaiement from "./pages/admin/AdminPaiement";
import ParticipantDoc from "./pages/participant/ParticipantDoc";
import ModalUser from "./components/modals/Modal-User";

function App() {
  const state = useSelector(loginSelector);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/badge" element={<Badge />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/badge-pdf" element={<BadgePdf />} />
        <Route path="/payment/:token" element={<PaymentPage />} />
        <Route path="/mail" element={<Mail />} />
        <Route
          path="/admin/login"
          element={
            !state.adminIsAuthenticated ? (
              <LoginAdmin />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        <Route
          path="/login"
          element={!state.isAuthenticated ? <Login /> : <Navigate to="/home" />}
        />
        <Route
          path="/home"
          element={
            state.isAuthenticated ? (
              <ParticipantHome />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        {/* <Route
          path="/documents"
          element={
            state.isAuthenticated ? (
              <ParticipantDoc />
            ) : (
              <Navigate to="/login" />
            )
          }
        /> */}
        <Route
          path="/admin/participants"
          element={
            state.adminIsAuthenticated ? (
              <AdminParticipant />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin"
          element={
            state.adminIsAuthenticated ? (
              <AdminHome />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/partners"
          element={
            state.adminIsAuthenticated ? (
              <AdminPartners />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/config"
          element={
            state.adminIsAuthenticated ? (
              <AdminConfigEmail />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/form"
          element={
            state.adminIsAuthenticated ? (
              <AdminForm />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/payment"
          element={
            state.adminIsAuthenticated ? (
              <AdminPaiement />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
      </Routes>
      <SnackBarComponent />
      <ModalUser/>
    </>
  );
}

export default App;
