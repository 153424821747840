import React, { useState } from "react";
import "./Nav.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLanguage } from "i18next";
import  i18next from "i18next";


function Nav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isActive , setIsActive] = useState(false);

  const handleShowLang = () => {
    console.log("handleShowLang")
    setIsActive(!isActive);
   
  }

  const handleChangeLang = (lan) => {
    localStorage.setItem('locales', lan);
    changeLanguage(lan);
  }

  return (
    <nav className="nav-karim">
      <div className="nav-brand">
        <Link to="https://www.cyberafricaforum.com">
          <img
            src="https://www.cyberafricaforum.com/new-caf/logo/logo.png?ver=1"
            alt=""
          />
        </Link>
      </div>

      <div className="d-flex align-items-center "  onClick={handleShowLang}>
        <div  className="nav-lang me-2" id="nav-button">
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div>
          <span style={{color: 'white'}}>{i18next.language === 'en' ? "EN" : "FR"}</span>
        </div>
        <div className={isActive ? "lang active" : "lang"} >
          <ul>
            <a href="#fr" onClick={() => handleChangeLang('fr')}>
              <li>FR</li>
            </a>
            <a href="#en" onClick={() => handleChangeLang('en')}>
              <li>EN</li>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
