import React from "react";
import './Mail.css';
function Mail() {
  return (
    <div>
      <div className="header">
        <img src="images/mail-header.jpg" alt="" className="w-100" />
      </div>

      <div className="mail-content container ">
        <p>
          {" "}
          Cher Monsieur <strong>Karim KOUYATE</strong>,{" "}
        </p>
        <p>Nous vous remercions pour votre pré-inscription au #CAF2024. <strong>Nous insistons sur le fait qu’il ne s’agit pas d’une confirmation d’inscription.</strong> </p>
        <p>
            Vous recevrez une confirmation de votre pré-inscription « définitive » dans les prochains jours, suivi du lien qui vous donnera accès à la plateforme de paiement « le cas échéant ».
        </p>
        <p>
          Retrouvez toutes les informations relatives au CAF2024 sur notre site
          internet : <a href="https://cyberafricaforum.com" class="blue">www.cyberafricaforum.com</a>
        </p>
        <p>
          Vous pouvez également retrouver et partager toutes les actualités du
          #CAF2023 sur nos réseaux sociaux : <a href="https://twitter.com/CAF_forum" class="blue">Twitter</a>{" - "}
          <a href="https://www.linkedin.com/showcase/cyber-africa-forum/" class="blue">LinkedIn</a>{" - "} <a href="https://www.facebook.com/CyberAfricaForum" class="blue"> Facebook</a> 
          {" - "}
          <a href="https://www.instagram.com/cyberafricaforum/" class="blue"> Instagram.</a>
        </p>{" "}

        <p>  
            Pour toute question concernant l’organisation de l’événement, nous vous remercions de bien vouloir nous envoyer un courriel à l’adresse suivante : <span class="blue">contact@cyberafricaforum.com</span>
        </p>
        
        <p>Vous pouvez à tout moment modifier
        votre inscription en cliquant sur le lien ci-dessous :</p>

        <div className="button mb-2 ">
           <a href="http://127.0.0.1:3000/payment/csdgggdfvdfvf">Proceder au paiement</a>  
        </div>

        <div>
            <p>Cordialement,</p>
            <p>L’équipe d’organisation</p>
        </div>
        
      </div>

      <div className="footer">
        <img src="images/mail-footer.jpg" alt="" className="w-100" />
      </div>
    </div>
  );
}

export default Mail;
