import { combineReducers } from 'redux';
import modalSlice from './slices/modalSlice';
import registerSlice from './slices/registerSlice';
import countrySlice from './slices/countrySlice';
import  activityAreaSlice  from './slices/activityArea';
import authSlice from './slices/authSlice';
import paymentSlice from './slices/paymentSlice';
import utilSlice from './slices/utilSlice';
import userSlice from './slices/userSlice';
import participantSlice from './slices/participantSlice';

const rootReducer = combineReducers({
	modal: modalSlice,
	util : utilSlice,
	register : registerSlice,
	country : countrySlice,
	activityArea : activityAreaSlice,
	login : authSlice,
	payment : paymentSlice,
	user : userSlice,
	participant : participantSlice
});

export default rootReducer;
