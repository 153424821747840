import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Main.css";
import { openModal } from "../../redux/slices/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { registerSelector, registerThunk } from "../../redux/slices/registerSlice";
import {
  countrySelector,
  fetchCountriesThunk,
} from "../../redux/slices/countrySlice";
import {
  activityAreaSelector,
  fetchActivityAreasThunk,
} from "../../redux/slices/activityArea";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from 'react-i18next';
import { changeLanguage } from "i18next";
import i18next from "i18next";

function Main() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [civility, setCivility] = useState("M");
  const [civilityError, setCivilityError] = useState("");
  const [job, setJob] = useState("");
  const [jobError, setJobError] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [organisationError, setOrganisationError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [celNumber, setCelNumber] = useState("");
  const [celNumberError, setCelNumberError] = useState("");
  const [day, setDay] = useState("ALL-DAY");
  const [validate, setValidate] = useState("");
  const [participationMode, setParticipationMode] = useState("FACE-TO-FACE");
  const [expectation, setExpectation] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryIdError, setCountryIdError] = useState("");
  const [activityAreaId, setActivityAreaId] = useState("");
  const [activityAreaIdError, setActivityAreaIdError] = useState("");
  const [badgeMention, setBadgeMention] = useState("");
  const [badgeMentionError, setBadgeMentionError] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [validateError, setValidateError] = useState('');

  const onVerify = useCallback((token) => {
    // console.log(token);
    setToken(token);
  }, []);

  const { loading, hasErrors, errMsg } = useSelector(registerSelector);
  const { loading: countryLoading, data: countries } =
    useSelector(countrySelector);
  const { loading: activityAreaLoading, data: activityAreas } =
    useSelector(activityAreaSelector);
    const { t } = useTranslation();
  const formRef = useRef(null);
  useEffect(() => {
    dispatch(fetchCountriesThunk());
    dispatch(fetchActivityAreasThunk());

    return () => {};
  }, []);

  const reCaptchaComponent = useMemo(
    () => (
      <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
    ),
    [process.env.REACT_APP_YOUR_RECAPTCHA_SITE_KEY]
  );

  const handleCheckboxChange = () => {
    setValidateError(''); // Effacez l'erreur lorsque la case à cocher est modifiée
    setIsChecked(!isChecked);
  };

  const validateForm = () => {
    // changeLanguage('fr')
    let formulaireValide = true;



    console.log(validate);
    console.log(i18next.language);

    const country = countries.find((c) => c.id == countryId);
    if (name === "") {
      if(i18next.language==='en'){
        setNameError("Name field is required");
      }else{
        setNameError("Le champ nom est requis");
      }
     
      formulaireValide = false;
    } else {
      setNameError("");
    }
    if (email === "") {
      if(i18next.language==='en'){
        setEmailError("Email field is required");
      }else {
        setEmailError("Le champ email est requis");
      }
    
      formulaireValide = false;
    } else {
      setEmailError("");
    }
    if (lastName === "") {
      if(i18next.language==='en'){
        setLastNameError("Lastname field is required");
      }else {
        setLastNameError("Le champ prenom est requis");
      }
    
      formulaireValide = false;
    } else {
      setLastNameError("");
    }
    if (civility === "") {
      if(i18next.language==='en'){
        setCivilityError("Gender field is required");
      }else{
        setCivilityError("Le choix genre est requis");
      }
      
      formulaireValide = false;
    } else {
      setCivilityError("");
    }
    if (job === "") {
      if(i18next.language==='en'){
        setJobError("Function field is required");
      }else{  
        setJobError("Le champ fonction est requis");

      }
    
      formulaireValide = false;
    } else {
      setJobError("");
    }

    if (activityAreaId === "") {
      if(i18next.language==='en'){
        setActivityAreaIdError("Acitivity Area field is required");
      }else{  
        setActivityAreaIdError("Le champ Secteur d'activité est requis");
      }
      formulaireValide = false;
    } else {
      setActivityAreaIdError("");
    }

    if (countryId === "") {
      if(i18next.language==='en'){
        setCountryIdError("Country field is required");
      }else{  
        setCountryIdError("Le champ pays est requis");
      }
      formulaireValide = false;
    } else {
      setCountryIdError("");
    }


    if (organisation === "") {
      if(i18next.language==='en'){
        setOrganisationError("Organization field is required");
      }else {
        setOrganisationError("Le champ organisation est requis");
      }
      formulaireValide = false;
    } else {
      setOrganisationError("");
    }
    if (phoneNumber === "") {
      if(i18next.language==='en'){
        setPhoneNumberError("Phone number field is required");
      }else{
        setPhoneNumberError("Le champ numéro de téléphone est requis");
      }
     
      formulaireValide = false;
    } else {
      setPhoneNumberError("");
    }

    if (!isChecked) {
      if(i18next.language==='en'){
        setValidateError('You must check the box to validate.');
      }else{
        setValidateError('Vous devez cocher la case pour valider.');
      }
      
      return;
    }

    if (token !== "") {
      // Submit your form data
    } else {
      setCaptchaError("Please verify that you are not a robot.");
      dispatch(
        openModal({
          type: "SNACK-BAR",
          data: {
            severity: "error",
            message: "Please verify that you are not a robot.",
          },
        })
      );
      return;
    }


    const language = i18next.language === 'en' ? "English" : "Français"


    if (formulaireValide) {
      // Soumettre le formulaire
      //activity area à ajouter
      // badge_mention à ajouter
      const data = {
        participation_mode: participationMode,
        language: language,
        civility: civility,
        last_name: lastName,
        name: name,
        email: email,
        function: job,
        phone_number: phoneNumber,
        organisation_name: organisation,
        phone_number_wha: phoneNumber,
        category: "PARTICIPANT",
        expectations: expectation,
        country: country.name,
        country_id: countryId,
        status: "WAITING",
        cel_number : celNumber,
        activityAreaId: activityAreaId,
      };
      dispatch(registerThunk(data)).then((data) => {
        if (data.status === 201 || data.status === 200) {
          dispatch(openModal({ type: "SUCCESS-REGISTER" }));
          setName("");
          setLastName("");
          setCivility("M");
          setJob("");
          setOrganisation("");
          setPhoneNumber("");
          setCelNumber("");
          setBadgeMention("");
          setEmail("");
          setExpectation("");
        } else {
          dispatch(
            openModal({
              type: "SNACK-BAR",
              data: { severity: "error", message: data.data.message },
            })
          );
        }
      });
    } else {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <main className="main-karim">
      <div className="text-center text-part m-3 ">
        {reCaptchaComponent}
        {/* <h2 className="font2 fw-bold tc1 mb-3"  style={{fontSize:'3.5rem'}}>{t('registration')} #CAF2024</h2> */}
        <h3>
         {/* Le coût de la participation est de <strong>50.000</strong> fr */}
         {/* {t('participation_cost')} */}
        </h3>
      </div>
      <div className="form container mt-5"  style={{fontSize:'1.7rem'}}>  
        <div className="row">
          <div className="col-md-4">
          {/* {t('title')} {t('hello', { name: 'John' })} */}
            <h3 className="form-title"> {t('personal_info')}</h3>
          </div>
          <div className="col-md-8">
            <div className="d-flex align-items-center" ref={formRef}>
              <div className="w-30">
                <label htmlFor="">
                  {t('gender')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex w-70 form-gr">
                <div className="input-gr me-5">
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="Mme"
                    onChange={(e) => setCivility(e.target.value)}
                    checked={civility === "Mme"}
                  />
                  <label htmlFor="" className="label-radio">
                    Mme
                  </label>
                </div>
                <div className="input-gr">
                  <input
                    type="radio"
                    value="M"
                    onChange={(e) => setCivility(e.target.value)}
                    checked={civility === "M"}
                  />
                  <label htmlFor="" className="label-radio">
                    M.
                  </label>
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2 ">
                <label htmlFor="">
                  {t('name')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 form-gr">
                <div className="input-gr-text">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('name_placeholder')}
                    className="input"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {nameError && <p className="error">{nameError}</p>}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2 last_name">
                <label htmlFor="">
                  {t('last_name')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 last_name form-gr">
                <div className="input-gr-text">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('last_name_placeholder')}
                    className="input"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                  />
                  {lastNameError && <p className="error">{lastNameError}</p>}
                </div>
              </div>
            </div>
            <div className="d-flex mt-5 align-items-center">
              <div className="d-flex w-100 form-gr">
                <div className="input-gr-text">
                  <label htmlFor="">
                    {t('official_function')}
                    <span className="red ms-1">*</span>
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t("function_placeholder")}
                    className="input mt-2"
                    maxLength={200}
                    onChange={(e) => {
                      if (e.target.value.length <= 200) {
                        setJob(e.target.value);
                      }
                    }}
                    value={job}
                  />
                  {jobError && <p className="error">{jobError}</p>}
                </div>
              </div>
            </div>
            <div className="d-flex mt-5 align-items-center">
              <div className="d-flex w-100 form-gr">
                <div className="input-gr-text">
                  <label htmlFor="">
                    {t('organization')}
                    <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('organization')}
                    className="input mt-2"
                    onChange={(e) => setOrganisation(e.target.value)}
                    value={organisation}
                  />
                  {organisationError && (
                    <p className="error">{organisationError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2 phone_number">
                <label htmlFor="">
                {t('activity_area')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 phone_number form-gr">
                <div className="input-gr-text">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setActivityAreaId(e.target.value)}
                    style={{fontSize:'1.5rem'}}
                  >
                    <option value="-1" selected>
                     {t('choose')}
                    </option>
                    {activityAreas &&
                      activityAreas.map((a) => (
                        <option value={a.id} key={a.id}>{a.name}</option>
                      ))}
                  </select>

                  {activityAreaIdError && <p className="error">{activityAreaIdError}</p>}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2">
                <label htmlFor="">
                  {t('country')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 form-gr">
                <div className="input-gr-text">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCountryId(e.target.value)}
                    style={{fontSize:'1.5rem'}}
                  >
                    <option value="-1" selected>
                      {t('choose')}
                    </option>
                    {countries &&
                      countries.map((d) => 
                        {
                          return d &&  <option value={d.id} key={d.id}>{d.name}</option>
                        }
                       
                      )}
                  </select>

                  {countryIdError && <p className="error">{countryIdError}</p>}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2">
                <label htmlFor="">
                  {t('mail_address')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 form-gr">
                <div className="input-gr-text">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('mail_address')}
                    className="input"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {emailError && <p className="error">{emailError}</p>}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2 phone_number">
                <label htmlFor="">
                  {t('phone_number')} <span className="red">*</span>
                </label>
              </div>
              <div className="d-flex col-md-10 phone_number form-gr">
                <div className="input-gr-text">
                  <PhoneInput
                    defaultCountry="ci"
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                  />
                  {phoneNumberError && (
                    <p className="error">{phoneNumberError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-md-2 phone_number">
                <label htmlFor="">{t('phone')}</label>
              </div>
              <div className="d-flex col-md-10 phone_number form-gr">
                <div className="input-gr-text">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder={t('phone_number_placeholder')}
                    className="input"
                    onChange={(e) => setCelNumber(e.target.value)}
                    value={celNumber}
                  />
                  {celNumberError && <p className="error">{celNumberError}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-5">
          <div className="row">
            <div className="col-md-4">
              <h3 className="form-title">PARTICIPATION </h3>
            </div>
            <div className="col-md-8">
              <div className="d-flex align-items-center">
                <div>
                  <h4 className="cp">
                    {t('participation_description')}
                  </h4>
                  <br />
                  {/* <ul>
                    <li>
                    {t('pass_change_maker')}
                    </li>
                    <li>
                    {t('pass_vip')}
                    </li>
                  </ul> */}

                  <div>
                    <img src="images/pass-new.jpg" alt="" width="100%"/>
                  </div>
                  <br />
                  <p>{t('pay_advertisement')}</p>
                </div>
              </div>
              {/* <div className="d-flex mt-5 align-items-center">
                <div className="d-flex w-100 form-gr">
                  <div className="input-gr-text">
                    <label htmlFor="">
                      {t('participation_mode')}
                    </label>
                  </div>

                <div className="d-flex w-70 form-gr">
                <div className="input-gr me-5">
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="FACE-TO-FACE"
                    onChange={(e) => setParticipationMode(e.target.value)}
                    checked={participationMode === "FACE-TO-FACE"}
                  />
                  <label htmlFor="" className="label-radio">
                    {t('face-to-face')}
                  </label>
                </div>
                <div className="input-gr">
                  <input
                    type="radio"
                    value="ONLINE"
                    onChange={(e) => setParticipationMode(e.target.value)}
                    checked={participationMode === "ONLINE"}
                  />
                  <label htmlFor="" className="label-radio">
                  {t('online')}
                  </label>
                </div>
              </div>
                </div>
              </div>

              <div className="d-flex mt-5 align-items-center">
                <div className="d-flex w-100 form-gr">
                  <div className="input-gr-text">
                    <label htmlFor="">
                    {t('is_face_to_face')}
                    </label>
                  </div>

                <div className="d-flex w-70 form-gr" style={{flexWrap: 'wrap'}}>
                <div className="input-gr me-5" style={{width : "100%"}}>
                  <input
                    type="radio"
                    name=""
                    id=""
                    value="day-1"
                    onChange={(e) => setDay(e.target.value)}
                    checked={day === "day-1"}
                  />
                  <label htmlFor="" className="label-radio">
                    {t('day-1')}
                  </label>
                </div>

                <div className="input-gr" style={{width : "100%"}}>
                  <input
                    type="radio"
                    value="day-2"
                    onChange={(e) => setDay(e.target.value)}
                    checked={day === "day-2"}
                  />
                  <label htmlFor="" className="label-radio">
                  {t('day-2')}
                  </label>
                </div>

                <div className="input-gr" style={{width : "100%"}}>
                  <input
                    type="radio"
                    value="ALL-DAY"
                    onChange={(e) => setDay(e.target.value)}
                    checked={day === "ALL-DAY"}
                  />
                  <label htmlFor="" className="label-radio">
                   {t('all-day')}
                  </label>
                </div>
              </div>
                </div>
              </div>


              <div className="d-flex mt-5 align-items-center">
                <div className="d-flex w-100 form-gr">
                  <div className="input-gr-text">
                    <label htmlFor="">
                      {t('expectations')}
                    </label>
               
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="input mt-4"
                      placeholder=  {t('expectation_placeholder')}
                      onChange={(e) => setExpectation(e.target.value)}
                      value={expectation}
                    ></textarea>
                  </div>
                </div>
              </div> */}

              <div className="mt-4 mb-4">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="flexCheckChecked"   checked={isChecked}
              onChange={handleCheckboxChange}  />
                <label class="form-check-label" for="flexCheckChecked">
                  {t('newsletter')}
                </label>

                {validateError && <div style={{ color: 'red' }}>{validateError}</div>}
              </div>
              </div>
              <div className="submit-btn mt-2" onClick={() => validateForm()}>
                <span>{loading ? "Loading..." : t('submit')}</span>
              </div>
              <div className=" mt-3 mb-3" id="captcha">
                <p><span className="red">*</span> : {(t('required_fields'))}</p>
                <p>
                  {t('contact_us')}
                  <span className="cp">contact@cyberafricaforum.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
