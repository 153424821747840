import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar, utilSelector } from "../../redux/slices/utilSlice";

function ParticipantSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isOpen, modalType } = useSelector(utilSelector);

  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname ? "active--nav " : "";
  };
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div
      className={
        isOpen && modalType === "SIDEBAR"
          ? "sidebar sidebar--active"
          : "sidebar"
      }
    >
      <div>
        <div className="logo-top ">
          <Link to="/">
            <div className="logoBox ">
              <img
                src="https://www.cyberafricaforum.com/new-caf/logo/logo.png?ver=1"
                alt="mage-logo"
                className="image-logo"
              />
            </div>
          </Link>
          <div
            className="close-menu"
            id="sidebarResponsive"
            onClick={() => dispatch(closeSidebar({type : "SIDEBAR"}))}
          >
            <i className="fas fa-times " style={{ color: "white" }}></i>
          </div>
        </div>

        <div className="d-flex justify-content-center my-5">
          <div className="sep"></div>
        </div>

        <div className="sidebar-menu">
          <h3>Espace Participant</h3>
          <ul className="sidebar-card">
            <li className="sidebar-item">
              <Link
                to="/home"
                className={isActive("/home") + "d-flex align-items-center"}
              >
                <div className="icon-menu">
                  <title>Information</title>
                  <img src="/icones/user-avatar-filled.svg" alt="" />
                </div>
                <span>Information</span>
              </Link>
            </li>

            {/* <li>
              <Link
                to="/documents"
                className={
                  isActive("/documents") + "d-flex align-items-center "
                }
              >
                <div className="icon-menu">
                  <title>Documents</title>
                  <img src="/icones/document-attach.svg" alt="" />
                </div>
                <span>Documents</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="button--deconnexion" onClick={handleLogout}>
        <a href="#e">
          <i className="fas fa-sign-out-alt "></i> &nbsp; Déconnexion
        </a>
      </div>
    </div>
  );
}

export default ParticipantSidebar;
