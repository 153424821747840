import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { paymentService } from '../../services/paymentService';

const initialState = {
  loading : false,
  hasErrors: false,
  errMsg: '',
  data : []
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    checkout(state, action) {
        state.loading = true;
    },
    checkoutSuccess(state, action) {
      state.loading = false;
    },
    checkoutFailure(state, action) {
        state.loading = false;
        state.hasErrors = true;
        state.errMsg = action.payload;
        console.log('failure login');
      },

      getTotalPayment(state, {payload}) {
        state.loading = true;
      }

      , getTotalPaymentSuccess(state, {payload}) {
        state.loading = false;
        state.data = payload.data;
      },

      getTotalPaymentFailure(state, {payload}) {
        state.loading = false;
        state.hasErrors = true;
        state.errMsg = payload;
        console.log('failure login');
      }
  },
});

export const { getTotalPayment,getTotalPaymentSuccess,getTotalPaymentFailure, checkoutSuccess, checkout, checkoutFailure } = paymentSlice.actions;
export const paymentSelector = (state) => state.payment;

export const checkoutThunk = (data) => {
    return async (dispatch)=> {
        dispatch(checkout());
        try {
            const response = await axios.post('https://api-checkout.cinetpay.com/v2/payment', data);
            await paymentService(data);
            dispatch(checkoutSuccess());
            return response
          } catch (error) {
            console.error('Login failed:', error);
            dispatch(checkoutFailure(error.message));
            return error.response;
          }
    }
  
}


export const getTotalPaymentThunk = (data) => {
  return async (dispatch) => {
    dispatch(getTotalPayment());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/payments`,{
          headers: {
            authorization: 'Bearer ' + data.token
          }
        }
      );
      console.log(response.data);
      dispatch(getTotalPaymentSuccess(response.data));
      return response;
    } catch (error) {
      console.error("get payment failed:", error.message);
      dispatch(getTotalPaymentFailure(error.message));
      return error.response;
    }
  };
};

export default paymentSlice.reducer;
