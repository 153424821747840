import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from "react";
  import "./../main/Main.css";
  import { openModal } from "../../redux/slices/modalSlice";
  import { useDispatch, useSelector } from "react-redux";
  import { registerSelector, registerThunk } from "../../redux/slices/registerSlice";
  import {
    countrySelector,
    fetchCountriesThunk,
  } from "../../redux/slices/countrySlice";
  import {
    activityAreaSelector,
    fetchActivityAreasThunk,
  } from "../../redux/slices/activityArea";
  import { PhoneInput } from "react-international-phone";
  import "react-international-phone/style.css";
  
  import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { updateUserThunk, userSelector } from "../../redux/slices/userSlice";
import { loginSelector } from "../../redux/slices/authSlice";
import { useLocation } from "react-router-dom";
  
  function ParticipantFormMain() {
    const dispatch = useDispatch();
    
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [civility, setCivility] = useState("");
    const [civilityError, setCivilityError] = useState("");
    const [job, setJob] = useState("");
    const [jobError, setJobError] = useState("");
    const [organisation, setOrganisation] = useState("");
    const [organisationError, setOrganisationError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [celNumber, setCelNumber] = useState("");
    const [celNumberError, setCelNumberError] = useState("");
    const [expectation, setExpectation] = useState("");
    const [countryId, setCountryId] = useState("");
    const [activityAreaId, setActivityAreaId] = useState("");
    const [badgeMention, setBadgeMention] = useState("");
    const [badgeMentionError, setBadgeMentionError] = useState("");
    const [captchaError, setCaptchaError] = useState("");
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  
    const onVerify = useCallback((token) => {
        // console.log(token);
      setToken(token);
    }, []);
  
    const { hasErrors, errMsg } = useSelector(registerSelector);
    const { loading } = useSelector(userSelector);
    const { loading: countryLoading, data: countries } =
      useSelector(countrySelector);
    const state = useSelector(loginSelector);
    const { loading: activityAreaLoading, data: activityAreas } =
      useSelector(activityAreaSelector);
    const formRef = useRef(null);
    const location = useLocation();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedActivityArea, setSelectedActivityArea] = useState('');
    const nameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const civilityRef = useRef();
    const organisationRef = useRef();
    const jobRef = useRef();
    const activityAreaRef = useRef();
    const countryRef = useRef();
    const phoneNumberRef = useRef();
    const celNumberRef = useRef();
    // const expectationRef = useRef();


    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
    };

    
    const handleActivityAreaChange = (event) => {
      setSelectedActivityArea(event.target.value);
    };
    

    useEffect(() => {
      dispatch(fetchCountriesThunk());
      dispatch(fetchActivityAreasThunk());
      setSelectedCountry(state.user.country_id);
      setSelectedActivityArea(state.user.activities_area_id);
      setCivility(state.user.civility)
      return () => {};
    }, []);

  
  
    const reCaptchaComponent = useMemo(
        () => (
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        ),
        [process.env.REACT_APP_YOUR_RECAPTCHA_SITE_KEY]
      );
  
    const validateForm = () => {
      let formulaireValide = true;

      const formData = {
        name: nameRef.current.value,
        last_name : lastNameRef.current.value,
        civility : civility,
        email: emailRef.current.value,
        function : jobRef.current.value,
        organisation_name : organisationRef.current.value,
        activityAreaId : activityAreaRef.current.value,
        country_id: countryRef.current.value,
        phone_number : phoneNumberRef.current.value,
        cel_number : celNumberRef.current.value,
        // expectations : expectationRef.current.value,
        token : state.token
      };

      console.log(formData);
      if (formData.name === "") {
        setNameError("Le champ nom est requis");
        formulaireValide = false;
      } else {
        setNameError("");
      }
      if (formData.email === "") {
        setEmailError("Le champ email est requis");
        formulaireValide = false;
      } else {
        setEmailError("");
      }
      if (formData.last_name === "") {
        setLastNameError("Le champ prenom est requis");
        formulaireValide = false;
      } else {
        setLastNameError("");
      }
      if (formData.civility === "") {
        setCivilityError("Le choix civilité est requis");
        formulaireValide = false;
      } else {
        setCivilityError("");
      }
      if (formData.function === "") {
        setJobError("Le champ fonction est requis");
        formulaireValide = false;
      } else {
        setJobError("");
      }
      if (formData.organisation_name === "") {
        setOrganisationError("Le champ organisation est requis");
        formulaireValide = false;
      } else {
        setOrganisationError("");
      }
      if (formData.phone_number === "") {
        setPhoneNumberError("Le champ numéro de téléphone est requis");
        formulaireValide = false;
      } else {
        setPhoneNumberError("");
      }
  
      if (token !== "") {
        // Submit your form data
      } else {
        setCaptchaError("Please verify that you are not a robot.");
        dispatch(
          openModal({
            type: "SNACK-BAR",
            data: {
              severity: "error",
              message: "Please verify that you are not a robot.",
            },
          })
        );
        return;
      }
  
      if (formulaireValide) {
        
        const data = formData
        dispatch(updateUserThunk(data)).then((data) => {
          if (data.status === 201 || data.status === 200) {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "success", message: data.data.message },
              })
            );
          } else {
            dispatch(
              openModal({
                type: "SNACK-BAR",
                data: { severity: "error", message: data.data.message },
              })
            );
          }
        });
      } else {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

  
  
    return (
      <main className="main-karim">
        <div className="text-center text-part m-3 ">
          {reCaptchaComponent}
        </div>
        <div className="form container mt-5"  style={{fontSize:'1.7rem'}}>  
          <div className="row p-5">
            <div className="col-md-12">
              <h3 className="form-title">RENSEIGNEMENTS PERSONNELS </h3>
            </div>
            <div className="col-md-12 mt-4 container">
              <div className="d-flex align-items-center" ref={formRef}>
                <div className="w-30">
                  <label htmlFor="">
                    Civilité <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex w-70 form-gr">
                  <div className="input-gr me-5">
                    <input
                      type="radio"
                      value="Mme"
                      defaultChecked={state.user.civility === "Mme"}
                      ref={civilityRef}
                      // checked={civilityRef.current && civilityRef.current.value === "Mme"}
                      onChange={(e) => setCivility(e.target.value)}
                      checked={civility === "Mme"}
                    />
                    <label htmlFor="" className="label-radio">
                      Mme
                    </label>
                  </div>
                  <div className="input-gr">
                    <input
                      type="radio"
                      value="M"
                      defaultChecked={state.user.civility === "M"}
                      ref={civilityRef}
                      // checked={civilityRef.current && civilityRef.current.value === "M"}
                      onChange={(e) => setCivility(e.target.value)}
                      checked={civility === "M"}
                    />
                    <label htmlFor="" className="label-radio">
                      M.
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Nom <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="entrez votre nom"
                      className="input"
                      defaultValue={state.user.name}
                      ref={nameRef}
                   
                    />
                    {nameError && <p className="error">{nameError}</p>}
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Prenom(s) <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="entrez votre prenom"
                      className="input"
                     ref = {lastNameRef}
                      defaultValue={state.user.last_name}
                    />
                    {lastNameError && <p className="error">{lastNameError}</p>}
                  </div>
                </div>
              </div>
              <div className="d-flex mt-5 align-items-center">
                <div className="d-flex w-100 form-gr">
                  <div className="input-gr-text">
                    <label htmlFor="">
                      Fonction officielle (apparaissant sur le badge, nombre de
                      caracteres 20 max)
                      <span className="red ms-1">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Fonction"
                      className="input mt-2"
                      maxLength={20}
                      defaultValue={state.user.function}
                      ref={jobRef}
                    />
                    {jobError && <p className="error">{jobError}</p>}
                  </div>
                </div>
              </div>
              <div className="d-flex mt-5 align-items-center">
                <div className="d-flex w-100 form-gr">
                  <div className="input-gr-text">
                    <label htmlFor="">
                      Organisation/Société qui figurera sur votre badge{" "}
                      <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Organisation/Société "
                      className="input mt-2"
                      defaultValue={state.user.organisation_name}
                      ref={organisationRef}
                    />
                    {organisationError && (
                      <p className="error">{organisationError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Votre secteur d'activité <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={selectedActivityArea}
                      ref={activityAreaRef}
                      onChange={handleActivityAreaChange}
                      style={{fontSize:'1.5rem'}}
                    >
                      {/* <option value="-1" >
                        Choisir
                      </option> */}

                     
                      {activityAreas &&
                        activityAreas.map((a) => (
                          <>
                          <option value={a.id}>{a.name}</option>
                          </>))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Pays <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      value={selectedCountry}
                      ref={countryRef}
                      onChange={handleCountryChange}
                      style={{fontSize:'1.5rem'}}
                    >
                      {countries &&
                        countries.map((d) => (
                          <option value={d.id}>{d.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Adresse mail <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="adresse email"
                      className="input"
                      value={state.user.email}
                      ref={emailRef}
                    />
                    {emailError && <p className="error">{emailError}</p>}
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">
                    Téléphone portable <span className="red">*</span>
                  </label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <PhoneInput
                      defaultCountry="ci"
                      value={state.user.phone_number}
                      ref={phoneNumberRef}
                    />
                    {phoneNumberError && (
                      <p className="error">{phoneNumberError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-center">
                <div className="col-md-2">
                  <label htmlFor="">Téléphone fixe</label>
                </div>
                <div className="d-flex col-md-10 form-gr">
                  <div className="input-gr-text">
                    <input
                      type="text"
                      placeholder="entrez le numero "
                      className="input"
                      defaultValue={state.user.cel_number}
                      ref={celNumberRef}
                    />
                    {celNumberError && <p className="error">{celNumberError}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex mt-5">
            <div className="row p-5">
              <div className="col-md-12">
                {/* <h3 className="form-title">PARTCIPATION </h3> */}
              </div>
              <div className="col-md-12 ">
                <div className="d-flex align-items-center">
                  <div>
                    {/* <h4 className="cp">
                      Afin de preparer au mieux votre participation Cyber Africa
                      Forum (CAF) 2024, nous vous remercions de bien vouloir
                      compléter tous les champs ci-dessous.
                    </h4> */}
                  </div>
                </div>
  
                {/* <div className="d-flex mt-5 align-items-center">
                  <div className="d-flex w-100 form-gr">
                    <div className="input-gr-text">
                      <label htmlFor="">
                        Pouvez vous preciser vos attentes quant à votre
                        participation à cet évènement ?
                      </label>
                      <textarea
                      
                        cols="30"
                        rows="10"
                        className="input mt-2"
                        placeholder="Commencer à ecrire..."
                        defaultValue={state.user.expectations}
                        ref={expectationRef}
                      ></textarea>
                    </div>
                  </div>
                </div> */}
  
                <div className="submit-btn mt-2" onClick={() => validateForm()}>
                  <span>{loading ? "Loading..." : "Valider"}</span>
                </div>
                <div className=" mt-3 mb-3" id="captcha">
                  <p>
                    Pour tous changements ou informations supplementaires,
                    contactez nous directement par email :{" "}
                    <span className="cp">contact@cyberafricaforum.com</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
  
  export default ParticipantFormMain;
  