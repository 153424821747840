import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getItemFromLocalStorage } from '../../features/helpers';

const initialState = {
  isAuthenticated: getItemFromLocalStorage('isAuthenticated',false),
  adminIsAuthenticated: getItemFromLocalStorage('adminIsAuthenticated',false),
  isAdmin : getItemFromLocalStorage('isAdmin',false),
  token: getItemFromLocalStorage('token',null),
  adminToken: getItemFromLocalStorage('adminToken',null),
  loading : false,
  hasErrors: false,
  errMsg: '',
  user : getItemFromLocalStorage('user',{}),
  adminUser : getItemFromLocalStorage('adminUser', {})
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
        state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.user.token;
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('token', action.payload.user.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
    },
    loginFailure(state, action) {
        state.loading = false;
        state.hasErrors = true;
        state.errMsg = action.payload;
        console.log('failure login');
      },

      loginInitiate(state, action) {
        state.loading = true;
      },

      loginInitiateSuccess(state, action) {
        state.loading = false;
      },
      loginInitiateFailure(state, action) {
          state.loading = false;
          state.hasErrors = true;
          state.errMsg = action.payload;
          console.log('failure login');
        },

      adminLogin(state, action) {
        state.loading = true;
      },

      adminLoginSuccess(state, action) {
        state.loading = false;
        state.adminIsAuthenticated = true;
        state.adminToken = action.payload.user.token;
        state.adminUser = action.payload.user;
        state.isAdmin = true;
        localStorage.setItem('adminToken', action.payload.user.token);
        localStorage.setItem('isAdmin', true);
        localStorage.setItem('adminIsAuthenticated', true);
        localStorage.setItem('adminUser', JSON.stringify(action.payload.user));
      },

      adminLoginFailure(state, action) {
        state.loading = false;
        state.hasErrors = true;
        state.errMsg = action.payload;
        console.log('failure login');
      },

      

      logout(state) {
        state.isAuthenticated = false;
        state.token = null;
        state.user = {};
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token');
        localStorage.removeItem('user'); 
      },

    logoutAdmin(state) {
      state.adminIsAuthenticated = false;
      state.adminToken = null;
      state.isAdmin = false;
      state.adminUser = {};
      localStorage.removeItem('adminIsAuthenticated');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('adminUser');
      localStorage.removeItem('isAdmin');
      
    },
  },
});

export const { logoutAdmin, loginInitiate, loginInitiateSuccess, loginInitiateFailure, loginSuccess, logout, login, loginFailure,adminLogin, adminLoginSuccess, adminLoginFailure} = authSlice.actions;
export const loginSelector = (state) => state.login;

export const loginThunk = (data) => {
    return async (dispatch)=> {
        dispatch(login());
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/finalize`, {
              verification_code: data.verificationCode,
              email : data.email,
              notification_token: data.notificationToken,
              from_website : data.fromWebsite
            });
            dispatch(loginSuccess({ user : { token : response.data.accessToken, ...response.data.data} }));
            return response
          } catch (error) {
            console.error('Login failed:', error);
            dispatch(loginFailure(error.message));
            return error.response;
          }
    }
  
}

export const adminLoginThunk = (data) => {
  return async (dispatch)=> {
      dispatch(adminLogin());
      try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/admin`, {
            email: data.email,
            password : data.password,
          });
          dispatch(adminLoginSuccess({ user : { token : response.data.accessToken, ...response.data.data} }));
          return response
        } catch (error) {
          console.error('Login failed:', error);
          dispatch(adminLoginFailure(error.message));
          return error.response;
        }
  }

}

export const loginInitiateThunk = (data) => {
    return async (dispatch)=> {
        dispatch(loginInitiate());
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/email`, {
              email : data.email,
            });
            dispatch(loginInitiateSuccess());
            return response
          } catch (error) {
            console.error('Login failed:', error);
            dispatch(loginInitiateFailure(error.message));
            return error.response;
          }
    }
  
}
export default authSlice.reducer;
