export const getItemFromLocalStorage = (key, defaultValue) => {
	const storedValue = localStorage.getItem(key);

	try {
		// Essayer de parser la valeur JSON
		const parsedValue = JSON.parse(storedValue);

		// Si le parsing réussit, renvoyer la valeur parsée
		return parsedValue !== null ? parsedValue : defaultValue;
	} catch (error) {
		// En cas d'erreur de parsing, renvoyer la valeur telle quelle
		return storedValue !== null ? storedValue : defaultValue;
	}
};

export function addThousandSeparators(amount) {
    // Convertir le montant en chaîne de caractères
    let strAmount = amount.toString();
  
    // Séparer la partie entière de la partie décimale
    let parts = strAmount.split('.');
    let integerPart = parts[0];
    let decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Ajouter des séparateurs de milliers à la partie entière
    let separatedIntegerPart = '';
    let count = 0;
    for (let i = integerPart.length - 1; i >= 0; i--) {
      count++;
      separatedIntegerPart = integerPart[i] + separatedIntegerPart;
      if (count % 3 === 0 && i !== 0) {
        separatedIntegerPart = '.' + separatedIntegerPart;
      }
    }
  
    // Retourner le montant avec les séparateurs ajoutés
    return separatedIntegerPart + decimalPart;
  }

  export  function formatDate(apiDate) {
    const date = new Date(apiDate);
  
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}`;
  }

