import React from "react";
import './Footer.css';
function Footer() {
  return (
    <footer className="container-fluid">
      <div className="row">
        <div
          className="footer-section ptb-50 text-center"
          style={{backgroundColor: "#fbfbfb"}}
        >
          <div className="logo-footer">
            <img
              src="https://www.cyberafricaforum.com/new-caf/logo/logo-c.png?ver=1"
              alt="CAF"
              width="170"
              height=""
            />
          </div>

          <div className="footer-menu mt-4">
            <a href="https://www.cyberafricaforum.com/participer">
              Participer au CAF
            </a>
            <span>●</span>
            <a href="https://www.cyberafricaforum.com/sponsoriser">
              Devenez partenaire
            </a>
            <span>●</span>
            <a href="https://www.cyberafricaforum.com/accreditation">Média</a>
            <span>●</span>
            <a href="https://www.cyberafricaforum.com/contact">Contact</a>
          </div>

          <ul className="widget-social">
            <li>
              <a
                href="https://www.facebook.com/CyberAfricaForum"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/CAF_forum" target="_blank">
                 <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@cyberafricaforum8430"
                target="_blank"
              >
               <i className="fa-brands fa-youtube"></i>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/cyberafricaforum/"
                target="_blank"
              >
               <i className="fa-brands fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://ci.linkedin.com/company/cibobs" target="_blank">
              <i className="fa-brands fa-linkedin"></i>
              </a>
            </li>
          </ul>

          <hr />

          <p style={{ fontSize: "12px" }}>
            <a href="https://www.cyberafricaforum.com/mention-legale" className="cp">
              Mentions légales
            </a>{" "}
            / Copyright © 2022. All Rights Reserved.
            <br />
            Un site réalisé par{" "}
            <a className="f500" href="https://novate-digital.com" target="_blank" className="cp">
              Novate Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
