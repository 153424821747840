import React from "react";
import "./../admin/AdminMain.css";
import AdminNav from "../../components/admin/AdminNav";
import Sidebar from "../../components/admin/Sidebar";
import SidebarResponseClose from "../../components/admin/SidebarResponseClose";
import ParticipantSidebar from "../../components/participant/ParticipantSidebar";
import ParticipantNav from "../../components/participant/ParticipantNav";
import Main from "../../components/main/Main";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ModalComponent from "../../components/modals/Modal";
import ParticipantFormMain from "../../components/participant/ParticipantFormMain";
function ParticipantHome() {
  return (
    <div className="backC">
      
      <ParticipantSidebar />
      <main className="main-content main-nelly">
        <ParticipantNav />

        <div>
         
            <SidebarResponseClose />
            <div className="tab--top ">
              {/* tableDash */}

              <div className="tableDash">
                <div className="body">
                  <ParticipantFormMain />

                  <ModalComponent />
                </div>
              </div>
            </div>
        </div>
      </main>
    </div>
  );
}

export default ParticipantHome;
