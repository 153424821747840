import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, modalSelector } from "../../redux/slices/modalSlice";
import "./Modal.css";
import { useNavigate } from "react-router-dom";
import { selectCategory } from "../../redux/slices/utilSlice";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
function ModalCategory() {
  const { isOpen, modalType } = useSelector(modalSelector);
  const [categories, setCategories] = useState([
    { id: 1, title: "Pass Change Maker", selected: true, amount : 77, lang : 'fr' },
    { id: 2, title: "Pass Impact Leader (VIP)", selected: false , amount : 229, lang : 'fr' },
    { id: 1, title: "Pass Change Maker", selected: true, amount : 83, lang : 'en' },
    { id: 2, title: "Pass Impact Leader (VIP)", selected: false , amount : 250, lang : 'en' },
  ]);

  // console.log(modalType, isOpen);

  const { t } = useTranslation();
  useEffect(() => {
    const ucat = [
      { id: 1, title: "Pass Change Maker", selected: true, amount : 77, lang : 'fr' },
      { id: 2, title: "Pass Impact Leader (VIP)", selected: false , amount : 229, lang : 'fr' },
      { id: 1, title: "Pass Change Maker", selected: true, amount : 83, lang : 'en' },
      { id: 2, title: "Pass Impact Leader (VIP)", selected: false , amount : 250, lang : 'en' },
    ].filter(c => c.lang === i18next.language);
    setCategories(ucat);
  }, [i18next.language]);

  const selectCat = (id) => {
        const updatedCategories = categories.filter(c => c.lang === i18next.language).map((cat) => {
          if (cat.id === id) {
            return { ...cat, selected: true };
          } else {
            return { ...cat, selected: false };
          }
        });
        setCategories(updatedCategories);
        dispatch(selectCategory({type : "SELECT-CATEGORY", data : categories.filter(c => c.lang === i18next.language).find(c => c.id === id)}));
        dispatch(closeModal("CATEGORY"));
  }

  const dispatch = useDispatch();
  const go = useNavigate();

  const customStyles = {
    content: {
      width: "40%", // Ajustez la largeur du modal selon vos besoins
      margin: "auto", // Centre le contenu horizontalement
      borderRadius: "10px", //
      height: "45%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Couleur de fond du backdrop (noir avec opacité)
      // boxShadow:'0px 6.400000095367432px 15px 0px rgba(0, 40, 210, 0.4), 0px 1.2000000476837158px 4px 0px rgba(0, 40, 210, 0.1)',
    },
  };

  const goToPaymentPage = () => {
    dispatch(closeModal("CATEGORY"));
  }

  return (
    <Modal
      isOpen={isOpen && modalType === "CATEGORY"}
      onRequestClose={() => dispatch(closeModal("CATEGORY"))}
      contentLabel="termes et conditions d utilisation"
      portalClassName="modal-term"
      style={customStyles}
    >
      <div className="mt-5">
        <div className="modal-custom-header">
          <div className="modal-header-text">
            <h3>{t('choose_pass')}</h3>
          </div>
        </div>

        <div className="modal-custom-body mt-3">

            {categories.filter(c => c.lang === i18next.language).map((category) => {
                return (
                <div
                    className="d-flex align-items-center justify-content-between mt-4"
                    onClick={() => selectCat(category.id)}
                    key={category.id}
                  >
                    <div
                      className={
                        category.selected
                          ? "card-mobile-money d-flex  flex-column checked"
                          : "card-mobile-money d-flex  flex-column "
                      }
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <h3 className="money-title">{category.title}</h3>
                      </div>
                      {/* <div className="img-container d-flex justify-content-center align-items-center">
                        {category.id === 1 ? (
                          <>
                            <img
                              src={`${process.env.REACT_APP_URL}/icones/diamond.svg`}
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <>
                              <img
                                src={`${process.env.REACT_APP_URL}/icones/diamond.svg`}
                                alt=""
                              />
                            </>{" "}
                          </>
                        )}
                      </div> */}
                    </div>
                    <div className="select-check d-flex justify-content-center align-items-center">
                      <div
                        className={
                            category.selected
                            ? "select d-flex align-items-center justify-content-center check-active"
                            : "select d-flex align-items-center justify-content-center"
                        }
                      >
                        {" "}
                        {/*check-active */}
                        {category.selected ? (
                          <i className="fa-solid fa-check"></i>
                        ) : null}
                      </div>
                    </div>
                  </div>)
            })}
        
        </div>

        <div className="modal-footer-c"></div>
      </div>
    </Modal>
  );
}

export default ModalCategory;
