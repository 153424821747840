import React from "react";

import './Participant.css';
import { openActionMenuTab, utilSelector } from "../../redux/slices/utilSlice";
import { useDispatch, useSelector } from "react-redux";
import { deselectParticipant, participantSelector, selectAllParticipants, selectParticipant } from "../../redux/slices/participantSlice";
import { formatDate } from "../../features/helpers";

function ParticipantRow({user, users}) {

  const dispatch = useDispatch();

  const {data, isOpen, modalType} = useSelector(utilSelector);


  const {selectedParticipants} = useSelector(participantSelector);

  console.log(selectedParticipants)


  const handleCheckboxChange = (userId, isChecked) => {
    if (isChecked) {
      dispatch(selectParticipant(userId));
    } else {
      dispatch(deselectParticipant(userId));
    }
  };

 
 


 
 

  

  function getStatusClassName(status) {
    switch(status) {
      case 'WAITING':
        return 'waiting';
      case 'APPROVED':
        return 'approved';
      case 'REVOKED':
        return 'revoked';
      case 'BANNED':
        return 'banned';
      case 'CONFIRMED':
        return 'approved';
      default:
        return '';
    }
  }

  function getPayClassName(value) {
    switch(value) {
      case 1:
        return 'approved';
      case 0:
        return 'revoked';
      default:
        return 'revoked';
    }
  }

  function getStatusName(status) {
    switch(status) {
      case 'WAITING':
        return 'En attente';
      case 'APPROVED':
        return 'Approuvé';
      case 'CONFIRMED':
        return 'Confirmé';
      case 'REVOKED':
        return 'Rejété';
      case 'BANNED':
        return 'Banni';
      default:
        return '';
    }
  }

  const getPaymentValue = (value) =>{
    switch(value) {
      case 1:
        return 'Oui';
      case 0 : 
      return 'Non';
      default:
        return 'Non';
  }
}

  


  function generateId(id) {
    // Ajoutez des zéros à gauche pour atteindre la longueur souhaitée
    const paddedId = String(id).padStart(3, '0');
  
    // Ajoutez un préfixe si nécessaire
    const prefixedId = `0022${paddedId}`;
  
    return prefixedId;
  }

  

  const statusClassName = getStatusClassName(user.status);
  const payClassName =  getPayClassName(user.has_suscribed);
  return (
    <tr>
      <td>
        <input type="checkbox" name="" id=""
        checked={selectedParticipants.includes(user.id)}
        onChange={e => handleCheckboxChange(user.id, e.target.checked)}
        />
      </td>
      <td>{generateId(user.id)}</td>
      <td>{formatDate(user.created_at)}</td>
      <td>{user.category}</td>
      <td>{user.name + ' ' + user.last_name}</td>
      <td>{user.function}</td>
      <td>{user.organisation_name}</td>
      <td>{user.email}</td>
      <td>{user.phone_number}</td>
      <td className={`${statusClassName}`}>{getStatusName(user.status)}</td>
      <td className={`${payClassName}`}>{getPaymentValue(user.has_suscribed)}</td>
      <td>
        <button type="button" className="btn btn-secondary btn-sm" onClick={()=> {
          dispatch(openActionMenuTab({type : "ACTION-MENU-TAB", data : {id : user.id, name : user.name, last_name : user.last_name }}));
        }}>
          <i className="fas fa-ellipsis-h"></i>
        </button>
      </td>
    </tr>
  );
}

export default ParticipantRow;
