import React from "react";
import { Skeleton } from "@mui/material";

function PaymentLoader() {
  return (
    <>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="text" width={60} />
        </td>
        <td>
          <Skeleton variant="text" width={40} />
        </td>

        <td>
          <Skeleton variant="text" width={100} />
        </td>
        
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="text" width={60} />
        </td>
        <td>
          <Skeleton variant="text" width={40} />
        </td>

        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="text" width={60} />
        </td>
        <td>
          <Skeleton variant="text" width={40} />
        </td>
        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
      </tr>

      <tr>
        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={80} />
        </td>
        <td>
          <Skeleton variant="text" width={120} />
        </td>
        <td>
          <Skeleton variant="text" width={100} />
        </td>
        <td>
          <Skeleton variant="text" width={60} />
        </td>
        <td>
          <Skeleton variant="text" width={40} />
        </td>

        <td>
          <Skeleton variant="text" width={100} />
        </td>

        <td>
          <Skeleton variant="rectangular" width={24} height={24} />
        </td>
      </tr>
    </>
  );
}

export default PaymentLoader;
