import BadgePdf from "./badge/BadgePdf";
import html2pdf from 'html2pdf.js';
import { useState } from "react";
import ReactDOM  from 'react-dom/client';


const UsersList = () => {
    const [users] = useState([
        { name : 'John'}, { name : 'Doe'}
    ])
    const handleDownloadBadge = (user) => {
        const opt = {
            margin: 1,
            filename: `${user.name.replace(' ', '_')}_badge.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        const element = document.createElement('div');
        const b = ReactDOM.createRoot(element);
        b.render(<BadgePdf user={user} />)
        html2pdf().from(element).set(opt).save();
    };


    const handlePrintBadge = (user) => {
        const opt = {
            margin: 1,
            filename: `${user.name.replace(' ', '_')}_badge.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };
        
        // Crée un conteneur div temporaire
        const container = document.createElement('div');

        // Rend le composant BadgePdf dans le conteneur
        const b = ReactDOM.createRoot(container);
        b.render(<BadgePdf user={user} />)

        // Convertit le contenu HTML en PDF
        html2pdf().from(container).set(opt).outputPdf('dataurlnewwindow');
    };

    const handleDownloadAllBadges = () => {
        const opt = {
            margin: 1,
            filename: `all_badges.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
        };

        const container = document.createElement('div');

        users.forEach(user => {
            var b = ReactDOM.createRoot(container);
            b.render(<BadgePdf user={user} />)
        });

        html2pdf().from(container).set(opt).save();
    };

    return (
        <div className="users-list">
            {users.map((user, index) => (
                <div>
                    <p>{user.name}</p>
                    <button onClick={() => handleDownloadBadge(user)} className="me-2">Telechager</button>
                    <button onClick={() => handlePrintBadge(user)}>Imprimer</button>
                    <button onClick={() => handleDownloadAllBadges()}>Télécharger tous</button>
                </div>
            ))}
        </div>
    );
};

export default UsersList;