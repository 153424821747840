import React from "react";
import Header from "../components/header/Header";
import Main from "../components/main/Main";
import Footer from "../components/footer/Footer";
import ModalComponent from "../components/modals/Modal";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";

function Home() {
  const { t } = useTranslation();
  return (
    <>
      <div className="body">
        <Header
          title={t('header')}
        />
        <Main />
        <Footer />
        <ModalComponent />
      </div>
    </>
  );
}

export default Home;
