import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ModalComponent from '../components/modals/Modal'
import MainPayment from '../components/MainPayment'
import ModalPayment from '../components/modals/Modal-Payment'
import ModalCategory from '../components/modals/Modal-Category'
import { useTranslation } from 'react-i18next'

function PaymentPage() {
  
  const { t } = useTranslation();
  return (
    <div className="body">
    <Header title={t("payment_header")}/>
    <MainPayment/>
    <Footer/>
    <ModalPayment/>
    <ModalCategory/>
  </div>
 
  )
}

export default PaymentPage