// Badge.js
import React from 'react';
import QRCode from 'react-qr-code';
import './Badge.css';
import { useReactToPrint } from 'react-to-print';


const Badge = ({ name, jobTitle, photoURL, id }) => {
    const componentRef = React.useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
  return (
    <>
    <div className="custom-badge" ref={componentRef}>
      <div className="badge-header">
        <img className="badge-photo" src="https://www.cyberafricaforum.com/new-caf/logo/logo-c.png?ver=1" alt="Employee" />
      </div>
      <div className="badge-bande">
        <img src="images/bande.jpg" alt="" />
      </div>
      <div className="badge-details">
          <h1>PARTICIPANT</h1>
          <div className="badge-qrcode">
            <QRCode value={`ID: sbcjkqxjnijs`} />
          </div>
          <div className='badge-identity'>
            <h4>Karim Kouyate</h4>
            <h4>Lead Developer chez Novate</h4>
            <h4>Novate</h4>
          </div>
      </div>
    </div>
  <div>
  <button onClick={handlePrint}>Imprimer</button>
  </div>
  </>
  );
};

export default Badge;
