import axios from "axios";

export const paymentService = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payments`,data );
    if (res.status === 200) {
      return res.data;
    }
  } catch (e) {
    return e;
  }
};
