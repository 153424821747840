import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import './admin/AdminMain.css';
import {
  adminLoginThunk,
  loginSelector,
  logout,
} from "../redux/slices/authSlice";
import { useAuth } from "../context/AuthContext";
import { openModal } from "../redux/slices/modalSlice";
import { useNavigate } from "react-router-dom";
import Header from "../components/header/Header";

function LoginAdmin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");
  const { loading } = useSelector(loginSelector);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordContainsUppercase, setPasswordContainsUppercase] =
    useState(false);
  const [passwordContainsLowercase, setPasswordContainsLowercase] =
    useState(false);
  const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    console.log('toggle password visibility')
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    setPasswordLengthValid(password.length >= 8);
    setPasswordContainsUppercase(/[A-Z]/.test(password));
    setPasswordContainsLowercase(/[a-z]/.test(password));
    setPasswordContainsSpecialChar(/[^A-Za-z0-9]/.test(password));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailError("Le champs email ne peut être vide.");
      return;
    }
    if (password == "") {
      setPasswordError("Le champs mot de passe ne peut être vide.");
      return;
    }
    const data = {
      email: email,
      password: password,
    };
    if (!validateEmail(email)) {
      setEmailError("Veuillez entrer une adresse email valide.");
      return;
    }

    // Validate password
    // validatePassword(password);
   

    // if (!passwordLengthValid) {
    //   setPasswordError("Le mot de passe doit contenir au moins 8 caractères.");
    //   return;
    // }

    // if (!passwordContainsUppercase) {
    //   setPasswordError(
    //     "Le mot de passe doit contenir au moins une lettre majuscule."
    //   );
    //   return;
    // }

    // if (!passwordContainsLowercase) {
    //   setPasswordError(
    //     "Le mot de passe doit contenir au moins une lettre minuscule."
    //   );
    //   return;
    // }

    // if (!passwordContainsSpecialChar) {
    //   setPasswordError(
    //     "Le mot de passe doit contenir au moins un caractère spécial."
    //   );
    //   return;
    // }

 
    dispatch(adminLoginThunk(data)).then((data) => {
      if (data.status === 201 || data.status === 200) {
        setResult("connexion avec succès");
        setEmail("");
        setPassword("");
        navigate("/admin");
      } else {
        console.log(data);
        dispatch(
          openModal({
            type: "SNACK-BAR",
            data: { severity: "error", message: data.data.error },
          })
        );
      }
    });
  };

  return (
<>
{/* <Header title="Espace Administrateur"/> */}
<div class="header-1">
        <div class="container">
            <h1>Espace administrateur</h1>
        </div>
    </div>
<main>
<div class="part--connexion">
  <div class="part--connexion__left">
    <div class="form--login">
      <form onSubmit={handleSubmit}>
      <h1>Connexion</h1>

      <div class="form--login__part">
        <div class="input--group">
          <label for="">
            Adresse mail <span>*</span>
          </label>
          <input type="text"          
          value={email}
          onChange={(e) => setEmail(e.target.value)}/>

        {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        </div>

        <div class="input--group">
          <label for="">
            Mot de passe <span>*</span>
          </label>
          <input
            id="password-field"
            type={isPasswordVisible ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
          />
          <span
            toggle="#password-field"
            className={isPasswordVisible ? 'fa fa-fw field-icon fa-eye-slash toggle-password' : 'fa fa-fw fa-eye field-icon toggle-password'}
            onClick={togglePasswordVisibility}
          ></span>

        {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
        {/* {passwordLengthValid && <p style={{ color: 'green' }}>Le mot de passe contient au moins 8 caractères.</p>}
        {!passwordLengthValid && <p style={{ color: 'red' }}>Le mot de passe doit contenir au moins 8 caractères.</p>}
        {passwordContainsUppercase && <p style={{ color: 'green' }}>Le mot de passe contient au moins une lettre majuscule.</p>}
        {!passwordContainsUppercase && <p style={{ color: 'red' }}>Le mot de passe doit contenir au moins une lettre majuscule.</p>}
        {passwordContainsLowercase && <p style={{ color: 'green' }}>Le mot de passe contient au moins une lettre minuscule.</p>}
        {!passwordContainsLowercase && <p style={{ color: 'red' }}>Le mot de passe doit contenir au moins une lettre minuscule.</p>}
        {passwordContainsSpecialChar && <p style={{ color: 'green' }}>Le mot de passe contient au moins un caractère spécial.</p>}
        {!passwordContainsSpecialChar && <p style={{ color: 'red' }}>Le mot de passe doit contenir au moins un caractère spécial.</p>}
   */}    </div>  

        <div class="text-center mt-5 mb-5">
     
            <button type="submit" class="btn--site" onClick={handleSubmit}>
              {loading ? 'Loading...' : "Me connecter "}
            </button>
         
        </div>
      </div>
      </form>
    </div>
  </div>
</div>
</main>
</>
  );
}

export default LoginAdmin;
