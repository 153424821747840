import React from 'react'
import './AdminMain.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/slices/authSlice';
import AdminNav from '../../components/admin/AdminNav';
import Sidebar from '../../components/admin/Sidebar';
import SidebarResponseClose from '../../components/admin/SidebarResponseClose';
import { userSelector } from '../../redux/slices/userSlice';
import { paymentSelector } from '../../redux/slices/paymentSlice';
import { addThousandSeparators } from '../../features/helpers';
function AdminHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { totalUsersCount, totalUsersVipCount, totalUsersParticipantCount , totalWomenUsersCount} = useSelector(userSelector);

  const {data} = useSelector(paymentSelector);
  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname ? 'active--nav ' : '';
  };
  const handleLogout = (e) => {

    e.preventDefault();
    dispatch(logout());
    navigate('/admin/login');
}




  return (
   
    <div className="backC">
        
    
        <Sidebar/>
        <main className="main-content main-nelly">
        
        <AdminNav title="Total Participants"/>
        
    
            <div>
            <SidebarResponseClose/>

            <div className="tab--top ">
               <div className="container">
               
                    {/* <div>
                        <div className="d-flex align-items-center justify-content-between ">
                            <h3>Partenaires</h3>
                        </div>
    
                        <div className="row mt-4">
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--iconbl">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--iconrd">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--icongl">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--iconsl">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--icondm">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-md-4 col-lg-2">
                                <div className="card--partners">
                                    <div className="card--partners__top">
                                        <h3>Partenaires inscrits</h3>
    
                                        <div className="part--icon part--iconpl">
                                            <i className="fas fa-handshake"></i>
                                        </div>
                                    </div>
    
                                    <div className="card--partners__bottom">
                                        <h1>290</h1>
    
                                        <div>
                                            <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                            <span>Cette semaine</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                    <div className="row mt-4">
                        <div className="col-md-7 col-lg-8">
                           <div className="mt-5 ">
                                <div >
                                    <h3>Participants</h3>
                                </div>
            
                                <div className="row mt-4">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card--part">
                                            <div className="card--part__top">
                                                <div className="part--icon part--iconbd">
                                                    <i className="fas fa-handshake"></i>
                                                </div>

                                                <h3>Participants inscrits</h3>
                                            </div>
            
                                            <div className="card--part__bottom">
                                                <h1>{totalUsersCount}</h1>
            
                                                <div>
                                                    <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                                    <span>Cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="card--part">
                                            <div className="card--part__top">
                                                <div className="part--icon part--iconbd">
                                                    <img src="icones/star.svg" alt="" />
                                                </div>

                                                <h3>Participants VIP</h3>
                                            </div>
            
                                            <div className="card--part__bottom">
                                                <h1>{totalUsersVipCount}</h1>
                                                {/* -women */}
            
                                                <div>
                                                    <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                                    <span>Cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="card--part">
                                            <div className="card--part__top">
                                                <div className="part--icon part--iconbd">
                                                    <i className="fas fa-handshake"></i>
                                                </div>

                                                <h3>Participants Ordinaire</h3>
                                            </div>
            
                                            <div className="card--part__bottom">
                                                <h1>{totalUsersCount - totalUsersVipCount - totalWomenUsersCount}</h1>
            
                                                <div>
                                                    <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                                    <span>Cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-4">
                                        <div className="card--part">
                                            <div className="card--part__top">
                                                <div className="part--icon part--iconbd">
                                                    <i class="fa-solid fa-venus"></i>
                                                </div>

                                                <h3>Cyber Africa Woman</h3>
                                            </div>
            
                                            <div className="card--part__bottom">
                                                <h1>{totalWomenUsersCount}</h1>
            
                                                <div>
                                                    <p><i className="fas fa-long-arrow-alt-up"></i> +20</p>
                                                    <span>Cette semaine</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                           </div>

                           <div>
                                <div className="mt-5 ">
                                    <h3>Suivi des inscriptions</h3>
                                </div>

                                <div className="cardStats">

                                </div>
                           </div>
                        </div>

                        <div className="col-md-5 col-lg-4">
                           <div className="mt-5 ">
                                <h3>Paiements</h3>
                           </div>

                           <div className="card--pay mt-4">
                                <div className="card--pay__top">
                                    <div className="part--icon part--iconbd">
                                    <i class="fa-solid fa-credit-card"></i>
                                    </div>

                                    <h3>Montant total de paiement enregistré</h3>

                                    <h1>{data.paymentTotalAmount && addThousandSeparators(data.paymentTotalAmount) } XOF</h1>
                                </div>

                                <div className="card--pay__bottom">
                                    <h3>Pourcentage de paiement par moyens de paiement</h3>

                                    <div className="d-flex justify-content-between">
                                        <div className="percentBar percentBarbs">
                                            <p>Mobile money</p>
                                            <h1>{data.paymentTotalMomoPercent && (data.paymentTotalMomoPercent * 100).toFixed(2)}%</h1>
                                        </div>

                                        <div className="percentBar percentBarbl">
                                            <p>Carte Bancaire</p>
                                            <h1>{data.paymentTotalMomoPercent && (100 - (data.paymentTotalMomoPercent *100)).toFixed(2)}%</h1>
                                        </div>

                                        {/* <div className="percentBar percentBargr">
                                            <p>Paypal</p>
                                            <h1>60%</h1>
                                        </div> */}
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
               </div>
            </div>
    
            </div>
        </main>
    

       
    </div>
  )
}

export default AdminHome