import { createSlice } from '@reduxjs/toolkit';

const utilSlice = createSlice({
	name: 'util',
	initialState: {
		isOpen: false,
		modalType: null,
		data: {},
		catData :  { id: 1, title: "Pass Change Maker", selected: true, amount : 77 },
		catDataEn :  { id: 1, title: "Pass Change Maker", selected: true, amount : 83 },
	},
	reducers: {
		toggleSidebar: (state, {payload}) => {
			state.isOpen = !state.isOpen;
			state.modalType = payload.type;
		},
		closeSidebar: (state, {payload}) => {
			state.isOpen = false;
			state.modalType = payload.type;
		},

		openActionMenuTab : (state, {payload}) => {
			state.isOpen = true;
			state.modalType = payload.type;
			state.data = payload.data;
		},

		closeActionMenuTab : (state, {payload}) => {
			state.isOpen = false;
			state.modalType = payload.type;
		},
		selectCategory : (state, {payload}) => {
			console.log(payload.data.lang === 'en')
			if(payload.data.lang === 'en'){
				state.catDataEn = payload.data;
				console.log('en slice')
			}else{
				state.catData = payload.data;
				console.log('fr slice')
			}
		}
	},
});

export const {selectCategory, toggleSidebar, closeSidebar, openActionMenuTab, closeActionMenuTab } = utilSlice.actions;
export const utilSelector = (state) => state.util;
export default utilSlice.reducer;
